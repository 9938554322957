import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import AppRoutes from "./constants/app-routes";

const engine = new Styletron();

const routes = createBrowserRouter(AppRoutes);
const client = new QueryClient();
client.setDefaultOptions({
    queries: {
        cacheTime: 0,
    },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={client}>
            <StyletronProvider value={engine}>
                <BaseProvider theme={LightTheme}>
                    <ToasterContainer placement={PLACEMENT.bottomLeft} autoHideDuration={3000}>
                        <RouterProvider router={routes} />
                        <ReactQueryDevtools />
                    </ToasterContainer>
                </BaseProvider>
            </StyletronProvider>
        </QueryClientProvider>
    </React.StrictMode>,
);
