import { FormControl } from "baseui/form-control";
import { Select, SelectProps } from "baseui/select";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LANGUAGE_FORM_OPTIONS } from "../../constants/form-constants";

type LanguageSelectProps = Pick<SelectProps, "value" | "onChange"> & {
    name: string;
    disabled: boolean;
};

const RootLanguageSelect: React.FC<LanguageSelectProps> = ({ name, disabled }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    label={() => "Source Translation Language"}
                    caption={() =>
                        "This language will be used as the source language for all other translations and cannot be changed after creation."
                    }
                >
                    <Select
                        disabled={disabled}
                        value={[
                            {
                                label: LANGUAGE_FORM_OPTIONS.find((option) => option.id === value)
                                    ?.label,
                                id: value,
                            },
                        ]}
                        options={LANGUAGE_FORM_OPTIONS}
                        onChange={(option) => onChange(option.value[0].id)}
                        searchable={false}
                        clearable={false}
                        required
                    />
                </FormControl>
            )}
        />
    );
};

export default RootLanguageSelect;
