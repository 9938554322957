import React from "react";

interface FormHeaderProps {
    title: string;
}

const FormHeader: React.FC<FormHeaderProps> = ({ title }) => (
    <h1 className="pb-2 mb-6 text-4xl font-bold border-b border-b-gray-400">{title}</h1>
);

export default FormHeader;
