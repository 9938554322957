import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { API_LOGOUT } from "../constants/api-endpoints";
import { ROOT } from "../constants/routes";
import CurrentUser from "../globals/session";
import { addErrorToast, addSuccessToast } from "../helpers/addToast";
import AuthenticatedMoApiRequest, { FetchResponse } from "./AuthenticatedMoApiRequest";

const useLogout = () => {
    const navigate = useNavigate();
    return useQuery([], async () => FetchResponse(new AuthenticatedMoApiRequest(API_LOGOUT)), {
        retry: false,
        cacheTime: 0,
        onSuccess: () => {
            CurrentUser.clearToken();
            navigate(ROOT);
            addSuccessToast("Signed out!");
        },
        onError: (e: Error) => {
            CurrentUser.clearToken();
            navigate(ROOT);
            addErrorToast(e.message);
        },
    });
};

export default useLogout;
