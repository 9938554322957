/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from "@tanstack/react-query";
import { BACKOFFICE_LANGUAGE } from "../constants/constants";
import { API_LOGIN } from "../constants/api-endpoints";
import AuthenticatedMoApiRequest from "./AuthenticatedMoApiRequest";
import CurrentUser from "../globals/session";

const UNAUTHENTICATED = { isAuthenticated: false };

type AuthenticationParameters = { token: string };

const parseResponse = async (response: Response) => {
    if (response.status === 400 || response.status === 401)
        return { ...UNAUTHENTICATED, status: response.status };

    if (response.ok) {
        const { session_token: sessionToken } = await response.json();

        const isAuthenticated = Boolean(sessionToken);

        if (isAuthenticated) {
            CurrentUser.setToken(sessionToken);
            return { isAuthenticated };
        }
    }

    return { ...UNAUTHENTICATED, status: "UNKNOWN" };
};

const useTokenCheck = ({ token }: AuthenticationParameters) => {
    const {
        data: queryData,
        refetch,
        dataUpdatedAt: queryDataUpdatedAt,
        isLoading,
    } = useQuery(
        [API_LOGIN, token],
        async () => {
            const auth = token;
            const payload =
                // Build it from sessionToken
                (auth && ({ auth } as Partial<FormData>)) ||
                // Case: no authn info avaliable
                null;

            // Short circuit: no authn info avaliable
            if (payload === null) return UNAUTHENTICATED;

            var url = new URL(API_LOGIN);
            url.searchParams.set("lang", BACKOFFICE_LANGUAGE);

            // Build request with payload and language perference
            const request = new AuthenticatedMoApiRequest(url, {
                method: "POST",
                payload,
            });

            const response = await fetch(request);
            const data = await parseResponse(response);
            return data;
        },
        {
            staleTime: Infinity,
            retry: false,
            retryOnMount: false,
        },
    );

    return {
        data: queryData,
        isAuthenticated: queryData?.isAuthenticated,
        isAuthLoading: isLoading,
        authenticationRefreshedAt: queryDataUpdatedAt,
        refetch,
    };
};

export default useTokenCheck;
