import { UUID } from "crypto";
import {
    Langs,
    TranslationState,
    ResponseType,
    SchedulerPeriod,
    SchedulerWeekdays,
    SchedulerMonthPos,
    TextFields,
    Translatable,
} from "../constants/enums";

export interface RequestById {
    id: string;
}

export interface RequestByIdFromURL {
    id: string;
    url: URL;
}

export interface BoostHeading {
    id: string;
    name: string;
    root_locale: Langs;
    published: boolean;
    created_at: string;
    updated_at: string;
}

export interface BoostCategory {
    id: string;
    heading_id: string;
    name: string;
    root_locale: Langs;
    published: boolean;
    created_at: string;
    updated_at: string;
}

interface SchedulerSettings {
    week_day: SchedulerWeekdays;
    month_pos: SchedulerMonthPos;
    time_of_day: string;
    period: SchedulerPeriod;
    include_weekends: boolean;
}

export interface BoostSettings {
    style: {
        emoji: string;
        colour: string;
    };
    response_type: ResponseType;
    scheduler_settings: SchedulerSettings;
    duration: number;
    run_duration: number;
}

export interface BoostTemplate {
    id: string;
    title: string;
    published: boolean;
    archived: boolean;
    root_locale: Langs;
    message: string;
    value_proposition: string;
    cadence_hint: string;
    audience_hint: string;
    boost_settings: BoostSettings;
    categories: BoostCategory[];
    category_ids: string[];
    created_at: string;
    updated_at: string;
    author: string;
    usage_count: number;
}

export interface Translation {
    created_at: string;
    id: string;
    root_locale: Langs;
    locale: Langs;
    state: TranslationState;
    source_text: string;
    translation: string;
    translation_key: string;
    translation_key_id: string;
    translator_message: string;
    updated_at: string;
}

export interface TranslationKey {
    objectName: Translatable;
    objectID: UUID;
    fieldName: TextFields;
}

export function parseTranslationKey(t: Translation): TranslationKey {
    const tkSplit = t.translation_key.split(".");

    const tk: TranslationKey = {
        objectName: tkSplit[0] as Translatable,
        objectID: tkSplit[1] as UUID,
        fieldName: tkSplit[2] as TextFields,
    };
    return tk;
}

export interface TranslationStatus {
    locale: Langs;
    state: TranslationState;
    root_locale: Langs;
}

export interface Paginated<T> {
    results: T[];
    total_count: number; // the total number of the items.
    page_number: number; // current page number
    page_count: number; // total number of pages
    last_page: boolean;
    cursor: string; // next cursor is used for subsequent requests.
}

export interface Empty {}
