import { API_BASE, APP_ENV } from "./constants";

function APIURL(path: string) {
    return new URL(path, API_BASE[APP_ENV]);
}

export const API_LOGIN = APIURL("login");
export const API_LOGOUT = APIURL("logout");
export const API_BACKOFFICE_BOOSTS_HEADINGS = APIURL("v1/backoffice/boosts/headings");
export const API_BACKOFFICE_BOOSTS_CATEGORIES = APIURL("v1/backoffice/boosts/categories");
export const API_BACKOFFICE_BOOSTS_TEMPLATES = APIURL("v1/backoffice/boosts/templates");
export const API_BACKOFFICE_TRANSLATIONS = APIURL("v1/backoffice/translations");
export const API_BACKOFFICE_TRANSLATIONS_STATUS = APIURL("v1/backoffice/translations/status");
