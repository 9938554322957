import { Button, SIZE } from "baseui/button";
import { FormControl } from "baseui/form-control";
import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Langs, TextFields } from "../../constants/enums";
import RootLanguageSelect from "../selects/RootLanguageSelect";
import BoolCheckBox from "../selects/BoolCheckBox";
import DefaultText from "../inputs/DefaultText";

interface HeadingFormVals {
    name: string;
    published: boolean;
    root_locale: Langs;
}

interface HeadingFormProps {
    initialValues: HeadingFormVals;
    isEdit: boolean;
    isSubmitting: boolean;
    onSubmit: (data: HeadingFormVals) => void;
}

const HeadingForm: React.FC<HeadingFormProps> = ({
    initialValues,
    isEdit,
    isSubmitting,
    onSubmit,
}) => {
    const formMethods = useForm<HeadingFormVals>({
        defaultValues: initialValues,
    });
    const { control, handleSubmit } = formMethods;

    const handleFormSubmit = (data: HeadingFormVals) => onSubmit(data);

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
                className="flex flex-col mb-6 "
            >
                <DefaultText
                    fieldName={TextFields.Name}
                    fieldLabel="Name"
                    placeholder="The name of the heading"
                />
                <Controller
                    name="published"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl
                            label={() => "Published"}
                            caption={() =>
                                "Related categories, which are also published will be shown. It's still possible to hide specific categories."
                            }
                        >
                            <BoolCheckBox
                                label="Visible to everyone"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        </FormControl>
                    )}
                />
                <RootLanguageSelect name="root_locale" disabled={isEdit} />
                <Button
                    isLoading={isSubmitting}
                    type="submit"
                    className="place-self-center"
                    size={SIZE.large}
                >
                    {isEdit ? "Save" : "Create"}
                </Button>
            </form>
        </FormProvider>
    );
};

export default HeadingForm;
