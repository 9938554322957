import React from "react";
import { Button, SIZE } from "baseui/button";
import { Link } from "react-router-dom";
import { APP_ENV, SAML_URL, SSO_ENABLED } from "../constants/constants";
import DevtoolsLogin from "./DevtoolsLogin";

export default function Login() {
    return (
        <div className="relative flex flex-col items-center justify-center h-screen space-y-4">
            <h1 className="absolute text-6xl font-bold top-4 left-4">Mo Backoffice</h1>
            {SSO_ENABLED[APP_ENV] ? (
                <Link to={SAML_URL[APP_ENV] ?? ""}>
                    <Button
                        size={SIZE.large}
                        overrides={{
                            BaseButton: {
                                style: {
                                    paddingRight: "40px",
                                    paddingLeft: "40px",
                                },
                            },
                        }}
                    >
                        Login
                    </Button>
                </Link>
            ) : (
                <DevtoolsLogin />
            )}
        </div>
    );
}
