import React, { useEffect } from "react";
import { Spinner } from "baseui/spinner";
import { useNavigate, useParams } from "react-router-dom";
import useTokenCheck from "../../queries/useTokenCheck";
import CurrentUser from "../../globals/session";
import { CMS_ROOT } from "../../constants/routes";

const ConnectWithToken = () => {
    const navigate = useNavigate();
    const params = useParams();

    if (params.token === undefined) {
        throw new Error("Invalid request parameter: Token must be set");
    }

    CurrentUser.setToken(params.token);

    const { isAuthenticated } = useTokenCheck({ token: params.token });

    useEffect(() => {
        if (isAuthenticated) navigate(CMS_ROOT);
    });

    if (isAuthenticated === false) return <>Unauthorised</>;

    return <Spinner />;
};

export default ConnectWithToken;
