import APIAuthenticationError from "../constants/errors";
import CurrentUser from "../globals/session";

type MoApiRequestRequestInit = { payload?: Partial<FormData>; contentType?: string };

const DEFAULT_METHOD_GET = "GET";

export default class AuthenticatedMoApiRequest extends Request {
    constructor(endpoint: URL, init: RequestInit & MoApiRequestRequestInit = {}) {
        const { contentType, payload, body: passthroughBody, ...restInit } = init;

        const headers = new Headers();
        headers.append("Access-Token", CurrentUser.getToken());
        headers.append("Content-Type", contentType ?? "application/json");

        const body = passthroughBody || JSON.stringify(payload);

        super(endpoint, {
            method: DEFAULT_METHOD_GET,
            ...restInit,
            body,
            headers,
        });
    }
}

// Fetches and parses the response. If unsuccessful, an Error with the message from API is thrown.
// Callers have to handle these accordingly. i.e. Displaying them to the user.
export async function FetchResponse(request: AuthenticatedMoApiRequest) {
    const response = await fetch(request);
    var jres = await response.json();

    if (!response.ok) {
        if (response.status === 500) {
            throw new Error(
                ["Unknown error:", request.destination, response.status, response.statusText].join(
                    " ",
                ),
            );
        }

        // in 99% of the cases, the token is invalid
        if (response.status === 401) {
            CurrentUser.clearToken();
            throw new APIAuthenticationError("Token invalid");
        }

        if (response.status === 404) {
            throw new Error("Page not found", { cause: 404 });
        }

        throw new Error(jres.message);
    }

    return jres;
}
