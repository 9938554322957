import React from "react";
import { Link } from "react-router-dom";
import { Button } from "baseui/button";
import moSad from "../assets/moSad.svg";
import { ROOT } from "../constants/routes";

const AccessDenied: React.FC = () => (
    <div className="flex flex-col items-center text-center">
        <img src={moSad} alt="sad face" />
        <h1 className="pb-4 text-5xl font-bold">STOP RIGHT THERE 🙅🏻‍♂️</h1>
        <span className="text-xl max-w-[800px] mb-6">
            It looks like you do not have access to this application, if you require access to the
            backoffice app please get in touch with the engineering team.
        </span>
        <Link to={ROOT}>
            <Button>Back to Login</Button>
        </Link>
    </div>
);

export default AccessDenied;
