import React from "react";
import { useParams } from "react-router-dom";
import {
    useEditCategory,
    useGetCategoryById,
} from "../../../../../queries/boost_cms/useBoostCMSCategory";
import CategoryForm from "../../../../../components/categories/CategoryForm";
import { useGetHeadingById } from "../../../../../queries/boost_cms/useBoostCMSHeadings";
import TranslationStatus from "../../../../../components/translations/TranslationStatus";
import Loading from "../../../../../components/Loading";
import FormHeader from "../../../../../components/form/header";

const EditCategory: React.FC = () => {
    const params = useParams();

    if (params.categoryId === undefined) {
        throw new Error("Invalid request parameter: CategoryID must be set");
    }

    const { data: category } = useGetCategoryById({ id: params.categoryId });

    const { data: associatedHeading } = useGetHeadingById({
        id: category?.heading_id || "",
    });
    const { mutate: editCategory, isLoading: isSubmitting } = useEditCategory();

    if (!category || !associatedHeading) return <Loading />;

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-2 col-span-3">
                <TranslationStatus id={params.categoryId} />
            </div>
            <div className="col-start-5 col-span-full">
                <FormHeader title={`Editing '${category.name}'`} />
                <CategoryForm
                    initialValues={{
                        name: category.name,
                        published: category.published,
                        root_locale: category.root_locale,
                        heading_id: {
                            id: associatedHeading.id,
                            label: associatedHeading.name,
                        },
                    }}
                    isEdit
                    isSubmitting={isSubmitting}
                    onSubmit={(data) => {
                        if (!data.heading_id?.id) return;
                        editCategory({
                            ...data,
                            id: category?.id,
                            heading_id: data.heading_id?.id,
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default EditCategory;
