/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { StatefulPopover } from "baseui/popover";

interface EmojiPickerProps {
    onChange: (val: string) => void;
    value: string;
}

interface Emoji {
    native: string;
    shortcodes: string;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onChange, value }) => (
    <StatefulPopover
        triggerType="hover"
        content={
            <div>
                <Picker data={data} onEmojiSelect={(e: Emoji) => onChange(e.native)} />
            </div>
        }
    >
        <div
            style={{
                backgroundColor: "rgb(238, 238, 238)",
            }}
            className="w-full p-3 rounded-lg"
        >
            {value}
        </div>
    </StatefulPopover>
);

export default EmojiPicker;
