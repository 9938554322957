import React from "react";
import { useNavigate } from "react-router-dom";
import { CMS_ROOT, LOGIN } from "../constants/routes";
import CurrentUser from "../globals/session";

const RootPage: React.FC = () => {
    const navigate = useNavigate();
    const isUserAuthenticated = CurrentUser.isSignedIn();

    React.useEffect(() => {
        if (!isUserAuthenticated) {
            navigate(LOGIN);
        } else {
            navigate(CMS_ROOT);
        }
    }, [isUserAuthenticated, navigate]);

    return <></>;
};

export default RootPage;
