import {
    UseInfiniteQueryResult,
    UseQueryResult,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { BOOST_CMS_CATEGORIES_ROOT } from "../../constants/routes";
import { RequestById, BoostCategory, Paginated } from "../../models/types";
import { API_BACKOFFICE_BOOSTS_CATEGORIES } from "../../constants/api-endpoints";
import { addSuccessToast, addErrorToast } from "../../helpers/addToast";

interface CategoryFilters {
    search: string;
    headingId?: string;
    limit?: string;
}

const useInfiniteCategories = ({
    search,
    headingId,
    limit,
}: CategoryFilters): UseInfiniteQueryResult<Paginated<BoostCategory>> =>
    useInfiniteQuery(
        [API_BACKOFFICE_BOOSTS_CATEGORIES, search, headingId, limit],
        async ({ pageParam }) => {
            var url = new URL(API_BACKOFFICE_BOOSTS_CATEGORIES);
            url.searchParams.set("p[limit]", limit || "20");

            if (pageParam) {
                url.searchParams.set("p[cursor]", pageParam);
            }
            if (search) {
                url.searchParams.set("f[name]", search);
            }
            if (headingId) {
                url.searchParams.set("f[heading_id]", headingId);
            }

            return FetchResponse(new AuthenticatedMoApiRequest(url));
        },
        {
            onError: (error: Error) => addErrorToast(error.message),
            getNextPageParam: (lastPage) => lastPage.cursor,
        },
    );

const useGetCategoryById = ({ id }: RequestById): UseQueryResult<BoostCategory> =>
    useQuery(
        [API_BACKOFFICE_BOOSTS_CATEGORIES, id],
        async () =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_CATEGORIES, id].join("/")),
                ),
            ),
        {
            onError: (error: Error) => addErrorToast(error.message),
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );

const useCreateCategory = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Omit<BoostCategory, "id" | "created_at" | "updated_at">) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(new URL(API_BACKOFFICE_BOOSTS_CATEGORIES), {
                    method: "POST",
                    payload: payload as Partial<FormData>,
                }),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_BOOSTS_CATEGORIES]);
                addSuccessToast("Category created!");
                navigate(BOOST_CMS_CATEGORIES_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

const useEditCategory = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Omit<BoostCategory, "created_at" | "updated_at">) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_CATEGORIES, payload.id].join("/")),
                    {
                        method: "PUT",
                        payload: payload as Partial<FormData>,
                    },
                ),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_BOOSTS_CATEGORIES]);
                addSuccessToast("Category saved!");
                navigate(BOOST_CMS_CATEGORIES_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

export { useInfiniteCategories, useGetCategoryById, useCreateCategory, useEditCategory };
