import { Button, SIZE } from "baseui/button";
import { FormControl } from "baseui/form-control";
import React, { useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Select } from "baseui/select";
import { useDebounce } from "usehooks-ts";
import { Langs, TextFields } from "../../constants/enums";
import RootLanguageSelect from "../selects/RootLanguageSelect";
import BoolCheckBox from "../selects/BoolCheckBox";
import { useInfiniteHeadings } from "../../queries/boost_cms/useBoostCMSHeadings";
import DefaultText from "../inputs/DefaultText";

interface CategoryFormVals {
    name: string;
    published: boolean;
    root_locale: Langs;
    heading_id: { id: string; label: string } | undefined;
}

interface CategoryFormProps {
    initialValues: CategoryFormVals;
    isEdit: boolean;
    isSubmitting: boolean;
    onSubmit: (data: CategoryFormVals) => void;
}

const CategoryForm: React.FC<CategoryFormProps> = ({
    initialValues,
    isEdit,
    isSubmitting,
    onSubmit,
}) => {
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);

    const { data: headings, isLoading: isLoadingHeadings } = useInfiniteHeadings({
        search: debouncedSearch,
    });

    const HEADING_OPTIONS =
        headings?.pages[0]?.results.map((heading) => ({
            id: heading?.id,
            label: heading?.name,
        })) || [];

    const formMethods = useForm<CategoryFormVals>({
        defaultValues: initialValues,
    });

    const { control, handleSubmit } = formMethods;

    const handleFormSubmit = (data: CategoryFormVals) => onSubmit(data);

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
                className="flex flex-col mb-6 "
            >
                <DefaultText
                    fieldName={TextFields.Name}
                    fieldLabel="Name"
                    placeholder="The name of the category"
                />
                <Controller
                    name="published"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl
                            label={() => "Published"}
                            caption={() => "Only visible if the parent Heading is published!"}
                        >
                            <BoolCheckBox
                                label="Visible to everyone"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        </FormControl>
                    )}
                />
                <Controller
                    name="heading_id"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FormControl label={() => "Associated Heading"}>
                            <Select
                                value={value ? [value] : []}
                                options={HEADING_OPTIONS}
                                isLoading={isLoadingHeadings}
                                placeholder="Select associated heading"
                                required
                                onInputChange={(e) => setSearch(e.target.value)}
                                clearable
                                onClose={() => setSearch("")}
                                onChange={({ option }) => onChange(option)}
                            />
                        </FormControl>
                    )}
                />
                <RootLanguageSelect name="root_locale" disabled={isEdit} />
                <Button
                    isLoading={isSubmitting}
                    type="submit"
                    className="place-self-center"
                    size={SIZE.large}
                >
                    {isEdit ? "Save" : "Create"}
                </Button>
            </form>
        </FormProvider>
    );
};

export default CategoryForm;
