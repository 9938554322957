import React from "react";
import { useParams } from "react-router-dom";
import {
    useEditHeading,
    useGetHeadingById,
} from "../../../../../queries/boost_cms/useBoostCMSHeadings";
import HeadingForm from "../../../../../components/headings/HeadingForm";
import TranslationStatus from "../../../../../components/translations/TranslationStatus";
import Loading from "../../../../../components/Loading";
import FormHeader from "../../../../../components/form/header";

const EditHeading: React.FC = () => {
    const params = useParams();

    if (params.headingId === undefined) {
        throw new Error("Invalid request parameter: Heading ID must be set");
    }

    const { data: heading } = useGetHeadingById({
        id: params.headingId,
    });

    const { mutate: editHeading, isLoading: isSubmitting } = useEditHeading();

    if (!heading) return <Loading />;

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-2 col-span-3">
                <TranslationStatus id={params.headingId} />
            </div>
            <div className="col-start-5 col-span-full">
                <FormHeader title={`Editing '${heading.name}'`} />
                <HeadingForm
                    onSubmit={(data) => editHeading({ ...data, id: heading.id })}
                    isSubmitting={isSubmitting}
                    isEdit
                    initialValues={{
                        name: heading.name,
                        published: heading.published,
                        root_locale: heading.root_locale,
                    }}
                />
            </div>
        </div>
    );
};
export default EditHeading;
