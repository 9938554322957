import React from "react";
import { useParams } from "react-router-dom";
import { useGetTranslationById } from "../../queries/translations/useTranslations";
import TranslationForm from "../../components/translations/TranslationForm";
import { FlagMap, TextFieldLabels, TranslatableLabels } from "../../constants/enums";
import { parseTranslationKey } from "../../models/types";
import Loading from "../../components/Loading";
import FormHeader from "../../components/form/header";

const EditTranslation: React.FC = () => {
    const params = useParams();

    if (params.translationId === undefined) {
        throw new Error("Invalid request parameter: Translation ID must be set");
    }

    const { data: translation } = useGetTranslationById({
        id: params.translationId,
    });

    if (!translation) return <Loading />;

    const objectLabel = TranslatableLabels[parseTranslationKey(translation).objectName];
    const fieldLabel = TextFieldLabels[parseTranslationKey(translation).fieldName];

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-5 col-span-full">
                <FormHeader
                    title={`Editing ${fieldLabel} of ${objectLabel} ${FlagMap[translation.locale]}`}
                />
                <div className="flex flex-col pb-4 mb-4 font-bold border-b border-b-gray-200">
                    <span>{`Source Language: ${FlagMap[translation.root_locale]}`}</span>
                    <span>
                        Source Text: <span className="font-normal">{translation.source_text}</span>
                    </span>
                </div>
                <TranslationForm translation={translation} />
            </div>
        </div>
    );
};

export default EditTranslation;
