import { styled } from "baseui";
import React from "react";
import { Outlet } from "react-router-dom";
import HeaderNavigation from "../components/navigation/HeaderNavigation";
import Sidebar from "../components/Sidebar";
import CurrentUser from "../globals/session";

const GridContainer = styled("section", () => ({
    display: "grid",
    gridTemplateColumns: "repeat(24, 1fr)",
    gridTemplateRows: "62px 1fr",
    minHeight: "100vh",
}));

const GridContent = styled("main", () => ({
    gridColumnStart: 4,
    gridColumnEnd: 24,
    padding: "16px",
}));

const MainLayout: React.FC = () => (
    <GridContainer>
        <HeaderNavigation />
        {CurrentUser.isSignedIn() && <Sidebar />}
        <GridContent>{CurrentUser.isSignedIn() && <Outlet />}</GridContent>
    </GridContainer>
);

export default MainLayout;
