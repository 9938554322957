import React from "react";
import { Link } from "react-router-dom";
import { StatefulTooltip } from "baseui/tooltip";
import { FlagMap } from "../../constants/enums";
import { useGetTranslationsStatus } from "../../queries/translations/useTranslations";
import { formatStateLabel, getStateLabelClass } from "../../helpers/formatStateLabel";
import { TRANSLATIONS_BY_KEY, TRANSLATIONS_BY_KEY_AND_LOCALE } from "../../constants/routes";

const TranslationStatus: React.FC<{ id: string }> = ({ id }) => {
    const { data } = useGetTranslationsStatus({ id: id });

    return (
        <div className="flex flex-col w-full border rounded-lg border-slate-300">
            {data &&
                data.map((ts) => (
                    <StatefulTooltip
                        key={ts.locale}
                        content={`Show all translations for ${ts.locale}`}
                    >
                        <Link
                            key={ts.locale}
                            to={TRANSLATIONS_BY_KEY_AND_LOCALE({ key: id, locale: ts.locale })}
                            className="flex flex-row justify-between w-full p-3 transition-colors hover:bg-gray-100 first:rounded-t-lg"
                        >
                            <span>
                                {FlagMap[ts.locale]} {ts.locale}
                            </span>

                            <div
                                className={`rounded px-2 text-sm ${getStateLabelClass(
                                    ts.state,
                                )} flex items-center`}
                            >
                                {formatStateLabel(ts.state)}
                            </div>
                        </Link>
                    </StatefulTooltip>
                ))}
            <div className="p-3 transition-colors border-t rounded-b-lg hover:bg-gray-100 border-t-slate-300">
                <Link title="Show all translations" to={TRANSLATIONS_BY_KEY({ key: id })}>
                    <span>🌍 Show all translations</span>
                </Link>
            </div>
        </div>
    );
};

export default TranslationStatus;
