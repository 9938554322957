import { FetchNextPageOptions } from "@tanstack/react-query";
import { Button, KIND } from "baseui/button";
import React from "react";

interface PaginationProps {
    numPages: number | undefined;
    currentPage: number;
    setCurrentPage: (nextPage: number) => void;
    fetchNextPage: (options?: FetchNextPageOptions | undefined) => void;
}

const LeftArrow = () => <span>←</span>;
const RightArrow = () => <span>→</span>;

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    numPages,
    setCurrentPage,
    fetchNextPage,
}) => (
    <div className="flex items-center justify-center w-full space-x-4">
        <Button
            onClick={() => {
                setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
            startEnhancer={LeftArrow}
            kind={KIND.tertiary}
        >
            Prev
        </Button>
        <span className="font-bold">{`${currentPage} of ${numPages || 1}`}</span>
        <Button
            disabled={currentPage === numPages}
            endEnhancer={RightArrow}
            kind={KIND.tertiary}
            onClick={() => {
                setCurrentPage(currentPage + 1);
                fetchNextPage();
            }}
        >
            Next
        </Button>
    </div>
);

export default Pagination;
