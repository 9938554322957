import Cookies from "js-cookie";
import { APP_ENV, COOKIE_DOMAIN } from "../constants/constants";
import { CookieKeys } from "../constants/enums";

const CookieSettings = {
    expires: 7,
    path: "/",
    domain: COOKIE_DOMAIN[APP_ENV],
    secure: true,
};

const CurrentUser = (function () {
    var setToken = function (token: string) {
        Cookies.set(CookieKeys.AuthToken, token, CookieSettings);
    };

    var getToken = function () {
        return Cookies.get(CookieKeys.AuthToken) || "";
    };

    var isSignedIn = function () {
        return getToken() !== "";
    };

    var clearToken = function () {
        Cookies.remove(CookieKeys.AuthToken, CookieSettings);
    };

    return {
        isSignedIn: isSignedIn,
        setToken: setToken,
        getToken: getToken,
        clearToken: clearToken,
    };
})();

export default CurrentUser;
