import { useMutation } from "@tanstack/react-query";
import { BACKOFFICE_LANGUAGE } from "../constants/constants";
import { API_LOGIN } from "../constants/api-endpoints";
import AuthenticatedMoApiRequest, { FetchResponse } from "./AuthenticatedMoApiRequest";
import { addErrorToast } from "../helpers/addToast";

const UNAUTHENTICATED = { isAuthenticated: false };

export interface DevtoolsLoginDetails {
    email: string;
    password: string;
}

const useDevtoolsPasswordLogin = () =>
    useMutation(
        async ({ email, password }: DevtoolsLoginDetails) => {
            const payload =
                // Build payload from email and password
                (email && password && ({ email, password } as Partial<FormData>)) ||
                // Alternatively, build it from sessionToken
                null;

            // Short circuit: no authn info avaliable // TODO isn't this more an invalid request payload error?
            if (payload === null) return UNAUTHENTICATED;

            var url = new URL(API_LOGIN);
            url.searchParams.set("lang", BACKOFFICE_LANGUAGE);

            // Build request with payload and language perference
            return FetchResponse(
                new AuthenticatedMoApiRequest(url, {
                    method: "POST",
                    payload,
                }),
            );
        },
        {
            mutationKey: ["devtoolsPasswordLogin"],
            cacheTime: 0,
            onError: (e: Error) => addErrorToast(e.message),
        },
    );

export default useDevtoolsPasswordLogin;
