import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { TextCharLimits, TextFieldLabels, TextFields } from "../../constants/enums";

interface TextFieldProps {
    fieldName: TextFields;
    fieldLabel: string;
    placeholder: string;
    fieldLength?: number;
    isTextArea?: boolean;
}

const DefaultText: React.FC<TextFieldProps> = ({
    fieldName,
    fieldLabel,
    placeholder,
    fieldLength = TextCharLimits[fieldName] || 99,
    isTextArea = false,
}) => {
    const { control } = useFormContext();
    const [input, setInput] = useState<string>("");

    var error: string | undefined;

    var ccLabel = `${input.length}/${fieldLength.toString()}`;

    if (input.length > fieldLength) {
        error = `⚠️ Text of '${TextFieldLabels[fieldName]}' is too long (${ccLabel}). This won't break anything, but it will look ugly. You have been warned!`;
    }

    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value } }) => {
                setInput(value);
                return (
                    <FormControl
                        label={() => fieldLabel}
                        caption={`Character Count ${ccLabel}`}
                        error={error}
                    >
                        {isTextArea ? (
                            <Textarea
                                type="text"
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                rows={5}
                                required
                            />
                        ) : (
                            <Input
                                type="text"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                placeholder={placeholder}
                                required
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    );
};

export default DefaultText;
