import {
    UseInfiniteQueryResult,
    UseQueryResult,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RequestById, BoostTemplate, Paginated } from "../../models/types";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { BOOST_CMS_TEMPLATES_ROOT } from "../../constants/routes";
import { API_BACKOFFICE_BOOSTS_TEMPLATES } from "../../constants/api-endpoints";
import { addSuccessToast, addErrorToast } from "../../helpers/addToast";

interface TemplateFilters {
    search: string;
    categoryId: string;
    headingId: string;
}

const useInfiniteTemplates = ({
    search,
    categoryId,
    headingId,
}: TemplateFilters): UseInfiniteQueryResult<Paginated<BoostTemplate>> =>
    useInfiniteQuery(
        [API_BACKOFFICE_BOOSTS_TEMPLATES, search, categoryId, headingId],
        async ({ pageParam }) => {
            var url = new URL(API_BACKOFFICE_BOOSTS_TEMPLATES);

            url.searchParams.set("p[limit]", "20");

            if (pageParam) {
                url.searchParams.set("p[cursor]", pageParam);
            }
            if (search) {
                url.searchParams.set("f[name]", search);
            }
            if (categoryId) {
                url.searchParams.set("f[category_id]", categoryId);
            }
            if (headingId) {
                url.searchParams.set("f[heading_id]", headingId);
            }

            return FetchResponse(new AuthenticatedMoApiRequest(url));
        },
        {
            onError: (error: Error) => addErrorToast(error.message),
            getNextPageParam: (lastPage) => lastPage.cursor,
        },
    );

const useGetTemplateById = ({ id }: RequestById): UseQueryResult<BoostTemplate> =>
    useQuery(
        [API_BACKOFFICE_BOOSTS_TEMPLATES, id],
        async () =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_TEMPLATES, id].join("/")),
                ),
            ),
        {
            onError: (error: Error) => addErrorToast(error.message),
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );

const useCreateTemplate = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (
            payload: Omit<
                BoostTemplate,
                "id" | "usage_count" | "categories" | "created_at" | "updated_at"
            >,
        ) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(new URL(API_BACKOFFICE_BOOSTS_TEMPLATES), {
                    method: "POST",
                    payload: payload as Partial<FormData>,
                }),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_BOOSTS_TEMPLATES]);
                addSuccessToast("Template created!");
                navigate(BOOST_CMS_TEMPLATES_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

const useEditTemplate = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (
            payload: Omit<
                BoostTemplate,
                "usage_count" | "categories" | "created_at" | "updated_at"
            >,
        ) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_TEMPLATES, payload.id].join("/")),
                    {
                        method: "PUT",
                        payload: payload as Partial<FormData>,
                    },
                ),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_BOOSTS_TEMPLATES]);
                addSuccessToast("Template saved!");
                navigate(BOOST_CMS_TEMPLATES_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

export { useInfiniteTemplates, useGetTemplateById, useCreateTemplate, useEditTemplate };
