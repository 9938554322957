import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import React from "react";

interface TableTitleProps {
    title: string;
}

const TableTitle: React.FC<TableTitleProps> = ({ title }) => (
    <StatefulTooltip content={() => title} placement={PLACEMENT.top} showArrow>
        <span className="line-clamp-1">{title}</span>
    </StatefulTooltip>
);

export default TableTitle;
