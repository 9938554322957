import React from "react";
import { Link, useLocation, useMatches } from "react-router-dom";
import { ChevronRight } from "baseui/icon";

interface Breadcrumb {
    label: string;
    to: undefined | string | null;
}

const Breadcrumbs = () => {
    const matches = useMatches();
    const { pathname } = useLocation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageMatch: any = matches.find((match) => match.pathname === pathname); // Get the exact match on pathname
    const crumbs: Breadcrumb[] = pageMatch?.handle?.crumb;

    const crumbsLength = crumbs?.length;

    return (
        <div className="flex items-center justify-between">
            {crumbs?.map((crumb: Breadcrumb, i: number) => (
                <React.Fragment key={crumb.to ?? crumb.label}>
                    {crumb?.to ? (
                        <Link
                            to={crumb.to}
                            className="font-bold underline transition-all hover:text-purple-600"
                        >
                            {crumb.label}
                        </Link>
                    ) : (
                        <span>{crumb.label}</span>
                    )}
                    {i + 1 !== crumbsLength && <ChevronRight size={28} />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;
