import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { BOOST_CMS_CATEGORIES_CREATE } from "../../../../constants/routes";
import { useInfiniteCategories } from "../../../../queries/boost_cms/useBoostCMSCategory";
import Pagination from "../../../../components/Pagination";
import { SelectOption } from "../../../../constants/form-constants";
import CategoryFilters from "../../../../components/categories/CategoryFilters";
import { useInfiniteHeadings } from "../../../../queries/boost_cms/useBoostCMSHeadings";
import CategoryTable from "../../../../components/categories/CategoryTable";
import IndexTitle from "../../../../components/IndexTitle";
import IndexActions from "../../../../components/IndexActions";
import Loading from "../../../../components/Loading";

const CMSCategoriesIndex = () => {
    const params = useParams();

    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const [heading, setHeading] = useState<SelectOption>({ id: params.headingId || "", label: "" });
    const { data: headings } = useInfiniteHeadings({ search: "" });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const {
        data: paginatedResponse,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteCategories({ search: debouncedSearch, headingId: heading.id });

    const page = paginatedResponse?.pages[currentPage - 1];
    const categories = page?.results?.map((row) => ({
        id: row.id,
        name: row.name,
        heading_id: row.heading_id,
        published: row.published,
        root_locale: row.root_locale,
    }));

    if (!categories) return <Loading />;

    return (
        <>
            <IndexTitle title="Boost CMS Categories">
                <IndexActions to={BOOST_CMS_CATEGORIES_CREATE} label="Create" />
            </IndexTitle>

            <CategoryFilters
                searchValue={search}
                setSearchValue={setSearch}
                heading={heading}
                setHeading={setHeading}
                headings={headings?.pages[0].results}
            />

            <CategoryTable data={categories || []} isLoading={isLoading || isFetchingNextPage} />

            <Pagination
                numPages={page?.page_count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetchNextPage={fetchNextPage}
            />
        </>
    );
};

export default CMSCategoriesIndex;
