import {
    UseInfiniteQueryResult,
    UseQueryResult,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { RequestById, BoostHeading, Paginated } from "../../models/types";
import { BOOST_CMS_HEADINGS_ROOT } from "../../constants/routes";
import { API_BACKOFFICE_BOOSTS_HEADINGS } from "../../constants/api-endpoints";
import { addSuccessToast, addErrorToast } from "../../helpers/addToast";

const useInfiniteHeadings = ({
    search = "",
    limit = 20,
}: {
    search?: string;
    limit?: number;
}): UseInfiniteQueryResult<Paginated<BoostHeading>> =>
    useInfiniteQuery(
        [BOOST_CMS_HEADINGS_ROOT, search, limit],
        async ({ pageParam }) => {
            var url = new URL(API_BACKOFFICE_BOOSTS_HEADINGS);

            url.searchParams.set("p[limit]", limit.toString());

            if (pageParam) {
                url.searchParams.set("p[cursor]", pageParam);
            }
            if (search) {
                url.searchParams.set("f[name]", search);
            }

            return FetchResponse(new AuthenticatedMoApiRequest(url));
        },
        {
            onError: (error: Error) => addErrorToast(error.message),
            getNextPageParam: (lastPage) => lastPage.cursor,
        },
    );

const useGetHeadingById = ({ id }: RequestById): UseQueryResult<BoostHeading> =>
    useQuery(
        [API_BACKOFFICE_BOOSTS_HEADINGS, id],
        async () =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_HEADINGS, id].join("/")),
                ),
            ),
        {
            onError: (error: Error) => addErrorToast(error.message),
            enabled: !!id,
            refetchOnWindowFocus: false,
        },
    );

const useCreateHeading = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Omit<BoostHeading, "id" | "created_at" | "updated_at">) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(new URL(API_BACKOFFICE_BOOSTS_HEADINGS), {
                    method: "POST",
                    payload: payload as Partial<FormData>,
                }),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([BOOST_CMS_HEADINGS_ROOT]);
                addSuccessToast("Heading created!");
                navigate(BOOST_CMS_HEADINGS_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

const useEditHeading = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Omit<BoostHeading, "created_at" | "updated_at">) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_BOOSTS_HEADINGS, payload.id].join("/")),
                    {
                        method: "PUT",
                        payload: payload as Partial<FormData>,
                    },
                ),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([BOOST_CMS_HEADINGS_ROOT]);
                addSuccessToast("Heading saved!");
                navigate(BOOST_CMS_HEADINGS_ROOT);
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

export { useInfiniteHeadings, useGetHeadingById, useCreateHeading, useEditHeading };
