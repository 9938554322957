/* eslint-disable import/no-extraneous-dependencies */
import { StatefulPopover } from "baseui/popover";
import React, { LegacyRef } from "react";
import { CirclePicker } from "react-color";

interface ColourPickerProps {
    ref: LegacyRef<CirclePicker> | undefined;
    onChange: (val: string) => void;
    value: string;
}

const ColourPicker: React.FC<ColourPickerProps> = ({ ref, onChange, value }) => (
    <StatefulPopover
        triggerType="hover"
        placement="top"
        content={
            <div className="p-4 bg-white rounded-lg">
                <CirclePicker ref={ref} onChangeComplete={(e) => onChange(e.hex)} color={value} />
            </div>
        }
    >
        <div
            style={{
                backgroundColor: value,
            }}
            className="w-full p-3 text-white rounded-lg"
        >
            {value}
        </div>
    </StatefulPopover>
);

export default ColourPicker;
