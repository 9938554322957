import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import React from "react";
import { Langs, FlagMap, TranslationState } from "../../constants/enums";
import { TRANSLATIONS_EDIT } from "../../constants/routes";
import { formatStateLabel, getStateLabelClass } from "../../helpers/formatStateLabel";
import TableActionItem from "../TableActionItem";

interface Row {
    state: TranslationState;
    id: string;
    key: string;
    locale: Langs;
    translation: string;
}

const TranslationsTable: React.FC<BackofficeCMS.TableProps<Row>> = ({ data, isLoading }) => (
    <TableBuilder
        isLoading={isLoading}
        overrides={{ Root: { style: { marginBottom: "16px" } } }}
        data={data}
        emptyMessage={
            <span className="font-bold">
                No results were found, try adjusting your search criteria.
            </span>
        }
    >
        <TableBuilderColumn<Row> header="State">
            {(row) => (
                <span
                    className={`px-2 py-1 rounded-xl ${getStateLabelClass(
                        row.state as TranslationState,
                    )}`}
                >
                    {formatStateLabel(row.state as TranslationState)}
                </span>
            )}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="ID">{(row) => <span>{row.id}</span>}</TableBuilderColumn>
        <TableBuilderColumn<Row> header="Key">{(row) => <span>{row.key}</span>}</TableBuilderColumn>
        <TableBuilderColumn<Row> header="Language">
            {(row) => <span>{`${row.locale} ${FlagMap[row.locale]}`}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Translation">
            {(row) => <span>{row.translation}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Actions">
            {(row) => <TableActionItem to={TRANSLATIONS_EDIT({ id: row.id })} icon="✏️" />}
        </TableBuilderColumn>
    </TableBuilder>
);

export default TranslationsTable;
