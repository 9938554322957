import React, { useState } from "react";
import { Input } from "baseui/input";
import { useDebounce } from "usehooks-ts";
import { BOOST_CMS_HEADINGS_CREATE } from "../../../../constants/routes";
import HeadingTable from "../../../../components/headings/HeadingTable";
import { useInfiniteHeadings } from "../../../../queries/boost_cms/useBoostCMSHeadings";
import Pagination from "../../../../components/Pagination";
import IndexTitle from "../../../../components/IndexTitle";
import IndexActions from "../../../../components/IndexActions";
import Loading from "../../../../components/Loading";

const CMSHeadingsIndex = () => {
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const {
        data: paginatedResponse,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteHeadings({ search: debouncedSearch });

    const page = paginatedResponse?.pages[currentPage - 1];
    const headings = page?.results.map((row) => ({
        id: row.id,
        name: row.name,
        published: row.published,
        root_locale: row.root_locale,
    }));

    if (!headings) return <Loading />;

    return (
        <>
            <IndexTitle title="Boost CMS Headings">
                <IndexActions to={BOOST_CMS_HEADINGS_CREATE} label="Create" />
            </IndexTitle>

            <Input
                startEnhancer={() => "🔎"}
                placeholder="Search by name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                overrides={{
                    Root: { style: { width: "fit-content" } },
                }}
            />

            <HeadingTable data={headings || []} isLoading={isLoading || isFetchingNextPage} />

            <Pagination
                numPages={page?.page_count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetchNextPage={fetchNextPage}
            />
        </>
    );
};

export default CMSHeadingsIndex;
