import { TranslationState } from "../constants/enums";

const formatStateLabel = (state: TranslationState) => {
    switch (state) {
        case TranslationState.AwaitingApproval:
            return "Awaiting approval";
        default:
            return `${state[0].toUpperCase()}${state.slice(1)}`;
    }
};

const getStateLabelClass = (state: TranslationState) => {
    switch (state) {
        case TranslationState.Failed:
            return "bg-red-600 text-white";
        case TranslationState.Processing:
            return "bg-yellow-300 text-white";
        case TranslationState.Required:
            return "bg-red-400 text-white";
        case TranslationState.Submitted:
            return "bg-lime-300 text-white";
        case TranslationState.AwaitingApproval:
            return "bg-orange-500 text-white";
        default:
            return "bg-green-500 text-white";
    }
};

export { formatStateLabel, getStateLabelClass };
