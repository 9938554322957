import React, { useEffect } from "react";
import { Button, SIZE } from "baseui/button";
import { Controller, useForm } from "react-hook-form";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { useNavigate } from "react-router-dom";
import useDevtoolsPasswordLogin, {
    DevtoolsLoginDetails,
} from "../queries/useDevtoolsPasswordLogin";
import { addErrorToast, addSuccessToast } from "../helpers/addToast";
import CurrentUser from "../globals/session";
import { CMS_ROOT } from "../constants/routes";

const DEFAULT_VALUES: DevtoolsLoginDetails = { email: "", password: "" };

export default function DevtoolsLogin() {
    const { handleSubmit, control } = useForm<DevtoolsLoginDetails>({
        defaultValues: DEFAULT_VALUES,
    });

    const { mutate, data } = useDevtoolsPasswordLogin();

    const navigate = useNavigate();

    useEffect(() => {
        if (data && !data.super_admin) {
            addErrorToast("Computer says... No.");
            return;
        }
        if (data && data?.session_token) {
            CurrentUser.setToken(data?.session_token);
            navigate(CMS_ROOT);
            addSuccessToast("Signed in!");
        }
    }, [data, navigate]);

    return (
        <form onSubmit={handleSubmit((value) => mutate(value))}>
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <FormControl label="Email">
                        <Input {...field} />
                    </FormControl>
                )}
            />

            <Controller
                name="password"
                control={control}
                render={({ field }) => (
                    <FormControl label="Password">
                        <Input {...field} type="password" />
                    </FormControl>
                )}
            />

            <Button
                type="submit"
                size={SIZE.large}
                overrides={{
                    BaseButton: {
                        style: {
                            paddingRight: "40px",
                            paddingLeft: "40px",
                        },
                    },
                }}
            >
                Login
            </Button>
        </form>
    );
}
