import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { useTranslations } from "../../queries/translations/useTranslations";
import TranslationsTable from "../../components/translations/TranslationsTable";
import TranslationsFilters from "../../components/translations/TranslationsFilters";
import Pagination from "../../components/Pagination";
import { SelectOption } from "../../constants/form-constants";
import IndexTitle from "../../components/IndexTitle";
import Loading from "../../components/Loading";

const Translations = () => {
    const params = useParams();

    const [search, setSearch] = useState<string>(params.translation_key || "");
    const debouncedSearch = useDebounce(search, 500);
    const [locale, setLocale] = useState<SelectOption>(
        params.locale
            ? { id: params.locale, label: "All Languages" }
            : { id: "", label: "All States" },
    );
    const [state, setState] = useState<SelectOption>({ id: "", label: "All States" });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const {
        data: paginatedResponse,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
    } = useTranslations({ search: debouncedSearch, locale: locale.id, state: state.id });

    const page = paginatedResponse?.pages[currentPage - 1];
    const translations = page?.results.map((row) => ({
        state: row.state,
        id: row.id,
        key: row.translation_key,
        locale: row.locale,
        translation: row.translation,
    }));

    if (!translations) return <Loading />;

    return (
        <>
            <IndexTitle title="Translations" />

            <TranslationsFilters
                searchValue={search}
                setSearchValue={setSearch}
                locale={locale}
                setLocale={setLocale}
                state={state}
                setState={setState}
            />

            <TranslationsTable
                data={translations || []}
                isLoading={isLoading || isFetchingNextPage}
            />

            <Pagination
                numPages={page?.page_count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetchNextPage={fetchNextPage}
            />
        </>
    );
};

export default Translations;
