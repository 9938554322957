import React from "react";
import { Langs } from "../../../../../constants/enums";
import { useCreateCategory } from "../../../../../queries/boost_cms/useBoostCMSCategory";
import CategoryForm from "../../../../../components/categories/CategoryForm";

const DEFAULT_VALUES = {
    name: "",
    published: false,
    heading_id: undefined,
    root_locale: Langs.EN,
};

const CreateCategory: React.FC = () => {
    const { mutate: createCategory, isLoading: isSubmitting } = useCreateCategory();

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-5 col-span-full">
                <h1 className="pb-2 mb-6 text-4xl font-bold border-b border-b-gray-400">
                    Create Category
                </h1>
                <CategoryForm
                    initialValues={DEFAULT_VALUES}
                    isEdit={false}
                    isSubmitting={isSubmitting}
                    onSubmit={(data) => {
                        if (!data.heading_id?.id) return;
                        createCategory({
                            ...data,
                            heading_id: data.heading_id?.id,
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default CreateCategory;
