import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import React from "react";
import { FlagMap, Langs } from "../../constants/enums";
import { BOOST_CMS_TEMPLATES_EDIT, TRANSLATIONS_BY_KEY } from "../../constants/routes";
import TableActionItem from "../TableActionItem";
import TableTitle from "../TableTitle";

interface Row {
    id: string;
    title: string;
    published: boolean;
    archived: boolean;
    root_locale: Langs;
}

const TemplateTable: React.FC<BackofficeCMS.TableProps<Row>> = ({ data, isLoading }) => (
    <TableBuilder
        isLoading={isLoading}
        overrides={{ Root: { style: { marginBottom: "16px" } } }}
        data={data}
        emptyMessage={
            <span className="font-bold">
                No results were found, try adjusting your search criteria.
            </span>
        }
    >
        <TableBuilderColumn<Row> header="ID">{(row) => <span>{row.id}</span>}</TableBuilderColumn>
        <TableBuilderColumn<Row>
            header="Title"
            overrides={{
                TableBodyCell: {
                    style: {
                        maxWidth: "300px",
                    },
                },
            }}
        >
            {(row) => <TableTitle title={row.title} />}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Published">
            {(row) => <span>{row.published ? "✅" : "❌"}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Archived">
            {(row) => <span>{row.archived ? "✅" : "❌"}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Source Language">
            {(row) => <span>{`${row.root_locale} ${FlagMap[row.root_locale]}`}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn<Row> header="Actions">
            {(row) => (
                <div className="flex-row gap-2">
                    <TableActionItem
                        title="Show all translations"
                        to={TRANSLATIONS_BY_KEY({ key: row.id })}
                        icon="🌍"
                    />
                    <TableActionItem to={BOOST_CMS_TEMPLATES_EDIT({ id: row.id })} icon="✏️" />
                </div>
            )}
        </TableBuilderColumn>
    </TableBuilder>
);

export default TemplateTable;
