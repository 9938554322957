import {
    UseInfiniteQueryResult,
    useInfiniteQuery,
    UseQueryResult,
    useQuery,
    useQueryClient,
    useMutation,
} from "@tanstack/react-query";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";
import { Paginated, RequestById, Translation, TranslationStatus } from "../../models/types";
import {
    API_BACKOFFICE_TRANSLATIONS,
    API_BACKOFFICE_TRANSLATIONS_STATUS,
} from "../../constants/api-endpoints";
import { addErrorToast, addSuccessToast } from "../../helpers/addToast";

interface TranslationFilters {
    search: string;
    state: string;
    locale: string;
}

const useTranslations = ({
    search,
    state,
    locale,
}: TranslationFilters): UseInfiniteQueryResult<Paginated<Translation>> =>
    useInfiniteQuery(
        [API_BACKOFFICE_TRANSLATIONS, search, state, locale],
        async ({ pageParam }) => {
            var url = new URL(API_BACKOFFICE_TRANSLATIONS);

            url.searchParams.set("p[limit]", "20");

            if (pageParam) {
                url.searchParams.set("p[cursor]", pageParam);
            }
            if (search) {
                url.searchParams.set("f[key]", search);
            }
            if (state) {
                url.searchParams.set("f[state]", state);
            }
            if (locale) {
                url.searchParams.set("f[locale]", locale);
            }

            return FetchResponse(new AuthenticatedMoApiRequest(url));
        },
        {
            onError: (error: Error) => addErrorToast(error.message),
            getNextPageParam: (lastPage) => lastPage.cursor,
        },
    );

const useGetTranslationById = ({ id }: RequestById): UseQueryResult<Translation> =>
    useQuery(
        [API_BACKOFFICE_TRANSLATIONS, id],
        async () =>
            FetchResponse(
                new AuthenticatedMoApiRequest(new URL([API_BACKOFFICE_TRANSLATIONS, id].join("/"))),
            ),
        {
            onError: (error: Error) => addErrorToast(error.message),
        },
    );

const useCreateTranslation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Translation) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(new URL(API_BACKOFFICE_TRANSLATIONS), {
                    method: "POST",
                    payload: payload as Partial<FormData>,
                }),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_TRANSLATIONS]);
                addSuccessToast("Translation created successfully!");
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

const useEditTranslation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: Translation) =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_TRANSLATIONS, payload.id].join("/")),
                    {
                        method: "PUT",
                        payload: payload as Partial<FormData>,
                    },
                ),
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_BACKOFFICE_TRANSLATIONS]);
                addSuccessToast("Translation edited successfully!");
            },
            onError: (error: Error) => addErrorToast(error.message),
        },
    );
};

const useGetTranslationsStatus = ({ id }: RequestById): UseQueryResult<Array<TranslationStatus>> =>
    useQuery(
        [API_BACKOFFICE_TRANSLATIONS_STATUS, id],
        async () =>
            FetchResponse(
                new AuthenticatedMoApiRequest(
                    new URL([API_BACKOFFICE_TRANSLATIONS_STATUS, id].join("/")),
                ),
            ),
        {
            onError: (error: Error) => addErrorToast(error.message),
        },
    );

export {
    useTranslations,
    useGetTranslationById,
    useCreateTranslation,
    useEditTranslation,
    useGetTranslationsStatus,
};
