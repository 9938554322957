import { styled } from "baseui";
import { ArrowLeft } from "baseui/icon";
import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";

const SubNavigationContainer = styled("div", () => ({
    gridRowStart: 2,
    gridColumnStart: 1,
    gridColumnEnd: 25,
    borderBottom: "1px solid #e5e5e5",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    justifyItems: "start",
}));

const SubNavigation = () => {
    const navigate = useNavigate();

    return (
        <SubNavigationContainer>
            <button
                type="button"
                className="mr-4 transition-all bg-white border border-gray-600 rounded-full  w-min h-min hover:bg-slate-100"
                onClick={() => navigate(-1)}
            >
                <ArrowLeft size={28} />
            </button>
            <Breadcrumbs />
        </SubNavigationContainer>
    );
};

export default SubNavigation;
