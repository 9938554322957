import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import React from "react";
import { API_BACKOFFICE_BOOSTS_CATEGORIES } from "../../constants/api-endpoints";
import { FlagMap, Langs } from "../../constants/enums";
import {
    BOOST_CMS_CATEGORIES_EDIT,
    BOOST_CMS_TEMPLATES_BY_CATEGORY_ID,
    TRANSLATIONS_BY_KEY,
} from "../../constants/routes";
import useDelete from "../../queries/shared/useDelete";
import TableActionItem from "../TableActionItem";
import TableTitle from "../TableTitle";

interface Row {
    id: string;
    name: string;
    published: boolean;
    root_locale: Langs;
    heading_id: string;
}

const CategoryTable: React.FC<BackofficeCMS.TableProps<Row>> = ({ data, isLoading }) => {
    const { mutate } = useDelete();

    return (
        <TableBuilder
            isLoading={isLoading}
            overrides={{ Root: { style: { marginBottom: "16px" } } }}
            data={data}
            emptyMessage={
                <span className="font-bold">
                    No results were found, try adjusting your search criteria.
                </span>
            }
        >
            <TableBuilderColumn<Row> header="ID">
                {(row) => <span>{row.id}</span>}
            </TableBuilderColumn>
            <TableBuilderColumn<Row>
                header="Name"
                overrides={{
                    TableBodyCell: {
                        style: {
                            maxWidth: "300px",
                        },
                    },
                }}
            >
                {(row) => <TableTitle title={row.name} />}
            </TableBuilderColumn>
            <TableBuilderColumn<Row> header="Published">
                {(row) => <span>{row.published ? "✅" : "❌"}</span>}
            </TableBuilderColumn>
            <TableBuilderColumn<Row> header="Source Language">
                {(row) => <span>{`${row.root_locale} ${FlagMap[row.root_locale]}`}</span>}
            </TableBuilderColumn>
            <TableBuilderColumn<Row> header="Actions">
                {(row) => (
                    <div className="flex">
                        <TableActionItem
                            title="Show all translations"
                            to={TRANSLATIONS_BY_KEY({ key: row.id })}
                            icon="🌍"
                        />

                        <TableActionItem
                            title="Show all templates"
                            to={BOOST_CMS_TEMPLATES_BY_CATEGORY_ID({ id: row.id })}
                            icon="📇"
                        />
                        <TableActionItem
                            title="Edit"
                            to={BOOST_CMS_CATEGORIES_EDIT({ id: row.id })}
                            icon="✏️"
                        />
                        <TableActionItem
                            title="Delete"
                            onClick={() =>
                                mutate({
                                    id: row.id,
                                    url: API_BACKOFFICE_BOOSTS_CATEGORIES,
                                })
                            }
                            icon="🗑️"
                        />
                    </div>
                )}
            </TableBuilderColumn>
        </TableBuilder>
    );
};

export default CategoryTable;
