import React from "react";

const useScreenWidth = () => {
    const [isRestricted, setIsRestricted] = React.useState(window.innerWidth <= 1024);

    const handleWindowResize = () => {
        setIsRestricted(window.innerWidth <= 1024);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return { isRestricted };
};

export default useScreenWidth;
