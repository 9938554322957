import { Input } from "baseui/input";
import { Select } from "baseui/select";
import React, { SetStateAction } from "react";
import {
    LANGUAGE_FORM_OPTIONS,
    SelectOption,
    TRANSLATION_STATE_OPTIONS,
} from "../../constants/form-constants";

interface TranslationFiltersProps {
    searchValue: string;
    setSearchValue: React.Dispatch<SetStateAction<string>>;
    locale: SelectOption;
    setLocale: React.Dispatch<SetStateAction<SelectOption>>;
    state: SelectOption;
    setState: React.Dispatch<SetStateAction<SelectOption>>;
}

const TranslationsFilters: React.FC<TranslationFiltersProps> = ({
    searchValue,
    setSearchValue,
    locale,
    setLocale,
    state,
    setState,
}) => (
    <div className="flex space-x-2">
        <Input
            startEnhancer={() => "🔎"}
            placeholder="Search by ID, field name or type"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
        />
        <Select
            searchable={false}
            clearable={false}
            options={[{ id: "", label: "All Languages" }, ...LANGUAGE_FORM_OPTIONS]}
            value={[locale]}
            onChange={(params) => setLocale(params.option as SelectOption)}
        />
        <Select
            searchable={false}
            clearable={false}
            options={[{ id: "", label: "All States" }, ...TRANSLATION_STATE_OPTIONS]}
            value={[state]}
            onChange={(params) => setState(params.option as SelectOption)}
        />
    </div>
);

export default TranslationsFilters;
