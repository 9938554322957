import { FALLBACK_LANGUAGE } from "./avaliable-language";

export const DEFAULT_APP_ENV = "staging";
export const APP_ENV = process.env.REACT_APP_ENV || DEFAULT_APP_ENV;

export const COOKIE_DOMAIN: Record<string, string> = {
    production: "backoffice.mo.work",
    staging: "backoffice.mo-staging.work",
    devtools: "localhost",
};

export const BASE_URL: Record<string, string> = {
    production: "backoffice.mo.work",
    staging: "backoffice.mo-staging.work",
    devtools: "localhost:3000",
};

export const API_BASE: Record<string, string | undefined> = {
    devtools: "https://localhost:8088",
    production: "https://moapi.mo.work",
    staging: "https://moapi.mo-staging.work",
};

export const BACKOFFICE_LANGUAGE = FALLBACK_LANGUAGE;

export const SSO_ENABLED: Record<string, boolean | undefined> = {
    devtools: false,
    production: true,
    staging: true,
};

export const SAML_URL: Record<string, string | undefined> = {
    production: "https://api.thanksbox.co/saml/backoffice/login",
    staging: "https://moapi.mo-staging.work/saml/backoffice/login",
};
