import React from "react";
import useLogout from "../queries/useLogout";

const Logout: React.FC = () => {
    useLogout();
    return (
        <div className="grid h-screen place-content-center">
            <h1 className="font-bold text-8xl">Goodbye 👋</h1>
        </div>
    );
};
export default Logout;
