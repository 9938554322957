import React from "react";
import { styled } from "baseui";
import { Navigation } from "baseui/side-navigation";
import { useLocation, useNavigate } from "react-router-dom";

import {
    BOOST_CMS_CATEGORIES_ROOT,
    BOOST_CMS_HEADINGS_ROOT,
    BOOST_CMS_TEMPLATES_ROOT,
    TRANSLATIONS_ROOT,
} from "../constants/routes";

const GridSidebar = styled("aside", () => ({
    gridColumnStart: 1,
    gridColumnEnd: 4,
    borderRight: "1px solid #e5e5e5",
}));

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <GridSidebar>
            <Navigation
                items={[
                    { title: "Headings", itemId: BOOST_CMS_HEADINGS_ROOT },
                    {
                        title: "Categories",
                        itemId: BOOST_CMS_CATEGORIES_ROOT,
                    },
                    {
                        title: "Templates",
                        itemId: BOOST_CMS_TEMPLATES_ROOT,
                    },
                    {
                        title: "Translations",
                        itemId: TRANSLATIONS_ROOT,
                    },
                ]}
                activeItemId={location.pathname}
                onChange={({ event, item }) => {
                    event.preventDefault();
                    if (item?.itemId) navigate(item.itemId);
                }}
                overrides={{
                    NavItem: {
                        style: ({ $active, $theme }) => {
                            if (!$active)
                                return {
                                    ":hover": {
                                        color: $theme.colors.accent300,
                                    },
                                };
                            return {
                                backgroundColor: $theme.colors.accent300,
                                borderLeftColor: $theme.colors.mono900,
                                color: $theme.colors.mono900,
                                ":hover": {
                                    color: $theme.colors.accent400,
                                },
                                fontWeight: 800,
                            };
                        },
                    },
                }}
            />
        </GridSidebar>
    );
};

export default Sidebar;
