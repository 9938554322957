import React from "react";
import { Button, SIZE } from "baseui/button";
import { styled } from "baseui";
import { Link } from "react-router-dom";
import { LOGIN, LOGOUT } from "../../constants/routes";
import CurrentUser from "../../globals/session";

const GridHeader = styled("nav", () => ({
    gridRowStart: 1,
    gridColumnStart: 1,
    gridColumnEnd: 25,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #e5e5e5",
    minWidth: "100%",
    padding: "20px",
}));

const HeaderNavigation = () => (
    <GridHeader>
        <h1 className="text-3xl font-bold">Mo Backoffice</h1>
        <Link to={CurrentUser.isSignedIn() ? LOGOUT : LOGIN}>
            <Button size={SIZE.compact}>{CurrentUser.isSignedIn() ? "Sign out" : "Sign In"}</Button>
        </Link>
    </GridHeader>
);

export default HeaderNavigation;
