import React, { SetStateAction } from "react";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { SelectOption } from "../../constants/form-constants";
import { BoostHeading } from "../../models/types";

interface CategoryFiltersProps {
    searchValue: string;
    setSearchValue: React.Dispatch<SetStateAction<string>>;
    heading: SelectOption;
    setHeading: React.Dispatch<SetStateAction<SelectOption>>;
    headings: BoostHeading[] | undefined;
}

function headingOptions(headings: BoostHeading[] | undefined) {
    return (
        headings?.map((heading) => ({
            id: heading?.id,
            label: heading?.name,
        })) || []
    );
}

const CategoryFilters: React.FC<CategoryFiltersProps> = ({
    searchValue,
    setSearchValue,
    heading,
    setHeading,
    headings,
}) => (
    <div className="flex space-x-2">
        <Input
            startEnhancer={() => "🔎"}
            placeholder="Search by name"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
        />
        <Select
            searchable={false}
            clearable={false}
            options={[{ id: "", label: "All Headings" }, ...headingOptions(headings)]}
            value={[heading]}
            onChange={(params) => setHeading(params.option as SelectOption)}
        />
    </div>
);

export default CategoryFilters;
