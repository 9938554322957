import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../hooks/useScreenWidth";
import moSad from "../assets/moSad.svg";
import CurrentUser from "../globals/session";
import { ROOT } from "../constants/routes";

interface AuthenticationWrapperProps {
    children?: React.ReactNode;
}

const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = ({ children }) => {
    const navigate = useNavigate();
    const { isRestricted } = useScreenWidth();
    const isUserAuthenticated = CurrentUser.isSignedIn();

    useEffect(() => {
        if (!isUserAuthenticated) navigate(ROOT);
    }, [isUserAuthenticated, navigate]);

    return !isRestricted ? (
        <>{children}</>
    ) : (
        <div className="flex flex-col p-10 text-center">
            <img src={moSad} alt="Sad Mo" />
            <span>
                Oops! Looks like you are trying to access this app on an unsupported device.
            </span>
        </div>
    );
};

export default AuthenticationWrapper;
