import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button } from "baseui/button";
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import { parseTranslationKey, Translation } from "../../models/types";
import {
    useCreateTranslation,
    useEditTranslation,
} from "../../queries/translations/useTranslations";
import { TextFields, TextCharLimits, TranslationState } from "../../constants/enums";
import DefaultText from "../inputs/DefaultText";
import { TRANSLATION_STATE_OPTIONS } from "../../constants/form-constants";

interface TranslationFormValues {
    translation: string;
    state: TranslationState;
}

interface TranslationFormProps {
    translation: Translation;
}

const TranslationForm: React.FC<TranslationFormProps> = ({ translation }) => {
    const { mutate: editTranslation } = useEditTranslation();
    const { mutate: createTranslation } = useCreateTranslation();

    const textField = parseTranslationKey(translation).fieldName;
    const textAreaRequired = textField === TextFields.Message || textField === TextFields.ValueProp;

    const formMethods = useForm<TranslationFormValues>({
        defaultValues: {
            translation: translation.translation,
            state: translation.state,
        },
    });
    const { control, handleSubmit } = formMethods;

    const onSubmit = (values: TranslationFormValues) => {
        const payload = {
            ...translation,
            translation: values.translation,
            state: values.state,
        };

        if (translation.id) {
            editTranslation(payload);
        } else {
            createTranslation(payload);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DefaultText
                    fieldName={TextFields.Translation}
                    fieldLength={TextCharLimits[textField]}
                    fieldLabel="Translation"
                    placeholder="Weekly wins"
                    isTextArea={textAreaRequired}
                />
                <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl
                            label={() => "State"}
                            caption={() =>
                                "Set to done will lock this translation. Source language text is always locked to done."
                            }
                        >
                            <Select
                                disabled={translation.locale === translation.root_locale}
                                value={[
                                    {
                                        id: value,
                                        label: TRANSLATION_STATE_OPTIONS.find(
                                            (option) => option.id === value,
                                        )?.label,
                                    },
                                ]}
                                options={TRANSLATION_STATE_OPTIONS}
                                onChange={(option) => onChange(option.value[0].id)}
                                searchable={false}
                                clearable={false}
                                required
                            />
                        </FormControl>
                    )}
                />
                <Button type="submit">Save</Button>
            </form>
        </FormProvider>
    );
};

export default TranslationForm;
