import React from "react";
import { useCreateHeading } from "../../../../../queries/boost_cms/useBoostCMSHeadings";
import { Langs } from "../../../../../constants/enums";
import HeadingForm from "../../../../../components/headings/HeadingForm";

const DEFAULT_VALUES = {
    name: "",
    published: false,
    root_locale: Langs.EN,
};

const CreateHeading: React.FC = () => {
    const { mutate: createHeading, isLoading: isCreateHeadingLoading } = useCreateHeading();

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-5 col-span-full">
                <h1 className="pb-2 mb-6 text-4xl font-bold border-b border-b-gray-400">
                    Create Heading
                </h1>
                <HeadingForm
                    isSubmitting={isCreateHeadingLoading}
                    isEdit={false}
                    initialValues={DEFAULT_VALUES}
                    onSubmit={(data) => createHeading(data)}
                />
            </div>
        </div>
    );
};

export default CreateHeading;
