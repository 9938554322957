import { Checkbox, CheckboxProps, LABEL_PLACEMENT } from "baseui/checkbox";
import React from "react";

type BoolCheckBoxProps = Pick<CheckboxProps, "checked" | "onChange"> & {
    label: string;
};

const BoolCheckBox: React.FC<BoolCheckBoxProps> = ({ checked, onChange, label }) => (
    <Checkbox checked={checked} onChange={onChange} labelPlacement={LABEL_PLACEMENT.right}>
        {label}
    </Checkbox>
);

export default BoolCheckBox;
