import React from "react";
import { useParams } from "react-router-dom";
import {
    useEditTemplate,
    useGetTemplateById,
} from "../../../../../queries/boost_cms/useBoostCMSTemplates";
import TemplateForm from "../../../../../components/templates/TemplateForm";
import TranslationStatus from "../../../../../components/translations/TranslationStatus";
import Loading from "../../../../../components/Loading";
import FormHeader from "../../../../../components/form/header";

const EditTemplate: React.FC = () => {
    const params = useParams();

    if (params.templateId === undefined) {
        throw new Error("Invalid request parameter: Template ID must be set");
    }

    const { data: template } = useGetTemplateById({
        id: params.templateId,
    });

    const { mutate: editTemplate, isLoading: isSubmitting } = useEditTemplate();

    if (!template) return <Loading />;

    return (
        <div className="grid grid-cols-12 gap-10">
            <div className="col-start-2 col-span-3">
                <TranslationStatus id={params.templateId} />
            </div>
            <div className="col-start-5 col-span-full">
                <FormHeader title={`Editing '${template.title}'`} />
                <TemplateForm
                    onSubmit={(data) => editTemplate({ id: template.id, ...data })}
                    isSubmitting={isSubmitting}
                    isEdit
                    initialValues={template}
                    initialCategories={
                        template.categories?.map((cat) => ({
                            id: cat.id,
                            label: cat.name,
                        })) || []
                    }
                />
            </div>
        </div>
    );
};

export default EditTemplate;
