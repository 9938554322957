/* eslint-disable import/no-extraneous-dependencies */
import { eachMinuteOfInterval, format } from "date-fns";

const formatOption = (pattern: string) => (element: Date) => ({
    label: format(element, pattern),
    value: format(element, "HH:mm"),
});

const buildIntervals = (step: number) => [
    ...eachMinuteOfInterval(
        {
            start: new Date(0, 0, 0, 6, 0, 0),
            end: new Date(0, 0, 1, 0, 0, -1),
        },
        { step },
    ).map(formatOption("h:mm a")), // 6:00 AM -> 12:00 PM -> just before midnight

    ...eachMinuteOfInterval(
        {
            start: new Date(0, 0, 1, 0, 0, 0),
            end: new Date(0, 0, 1, 6, 0, -1),
        },
        { step },
    ).map(formatOption("K:mm a")), // 0:00 AM -> just before 6:00 AM
];

export default buildIntervals;
