import {
    Langs,
    FlagMap,
    ResponseType,
    ResponseTypeLabels,
    TranslationState,
    SchedulerMonthPos,
    SchedulerPeriod,
    SchedulerWeekdays,
} from "./enums";
import { formatStateLabel } from "../helpers/formatStateLabel";

export interface SelectOption {
    id: string;
    label: string;
}

export const RESPONSE_TYPE_OPTIONS: SelectOption[] = Object.values(ResponseType).map((rt) => ({
    label: ResponseTypeLabels[rt],
    id: rt,
}));

export const LANGUAGE_FORM_OPTIONS: SelectOption[] = Object.values(Langs).map((lang) => ({
    label: `${FlagMap[lang]} ${lang}`,
    id: lang,
}));

export const TRANSLATION_STATE_OPTIONS: SelectOption[] = Object.values(TranslationState).map(
    (state) => ({
        id: state,
        label: formatStateLabel(state),
    }),
);

const DAY = 24;
const WEEK = 7 * DAY;

export const DURATION_OPTION_SETS: Record<SchedulerPeriod, { id: number; label: string }[]> = {
    [SchedulerPeriod.Daily]: [
        { id: 0, label: "Ongoing" },
        { id: 1, label: "1 hour" },
        { id: 2, label: "2 hours" },
        { id: 3, label: "3 hours" },
        { id: 4, label: "4 hours" },
        { id: 5, label: "5 hours" },
        { id: 6, label: "6 hours" },
        { id: 7, label: "7 hours" },
        { id: 8, label: "8 hours" },
        { id: DAY, label: "1 day" },
    ],
    [SchedulerPeriod.Weekly]: [
        { id: 4, label: "4 hours" },
        { id: 8, label: "8 hours" },
        { id: 1 * DAY, label: "1 day" },
        { id: 2 * DAY, label: "2 days" },
        { id: 3 * DAY, label: "3 days" },
        { id: 4 * DAY, label: "4 days" },
        { id: 5 * DAY, label: "5 days" },
        { id: 6 * DAY, label: "6 days" },
        { id: 7 * DAY, label: "7 days" },
    ],
    [SchedulerPeriod.Monthly]: [
        { id: 1 * DAY, label: "1 day" },
        { id: 2 * DAY, label: "2 days" },
        { id: 3 * DAY, label: "3 days" },
        { id: 4 * DAY, label: "4 days" },
        { id: 5 * DAY, label: "5 days" },
        { id: 6 * DAY, label: "6 days" },
        { id: 1 * WEEK, label: "1 week" },
        { id: 2 * WEEK, label: "2 weeks" },
        { id: 3 * WEEK, label: "3 weeks" },
        { id: 4 * WEEK, label: "1 month" },
    ],
    [SchedulerPeriod.Once]: [
        { id: 1 * DAY, label: "1 day" },
        { id: 2 * DAY, label: "2 days" },
        { id: 3 * DAY, label: "3 days" },
        { id: 4 * DAY, label: "4 days" },
        { id: 5 * DAY, label: "5 days" },
        { id: 6 * DAY, label: "6 days" },
        { id: 1 * WEEK, label: "1 week" },
        { id: 2 * WEEK, label: "2 weeks" },
        { id: 3 * WEEK, label: "3 weeks" },
        { id: 4 * WEEK, label: "1 month" },
    ],
};

export const PERIOD_OPTIONS = Object.entries(SchedulerPeriod).map(([k, v]: [string, string]) => ({
    label: k,
    value: v,
}));
export const WEEKDAY_OPTIONS = Object.entries(SchedulerWeekdays).map(
    ([k, v]: [string, string]) => ({
        label: k,
        id: v,
    }),
);
export const MONTH_POS_OPTIONS = Object.entries(SchedulerMonthPos).map(
    ([k, v]: [string, string]) => ({
        label: k,
        id: v,
    }),
);

export const LIFETIME_OPTIONS: { id: number; label: string }[] = [
    { id: 0, label: "On going" },
    { id: 1 * WEEK, label: "1 week" },
    { id: 2 * WEEK, label: "2 weeks" },
    { id: 3 * WEEK, label: "3 weeks" },
    { id: 4 * WEEK, label: "4 weeks" },
    { id: 5 * WEEK, label: "5 weeks" },
    { id: 6 * WEEK, label: "6 weeks" },
];
