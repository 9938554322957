// avaliable-languages.ts
// This file contains the main set of language definitions for frontend.
// It should reflect actual usage & backend's RoR I18n config.
//
// See `.../conf/locales.js` for storybook & i18next-locales-sync config.
//

// Note
// Addition of new languages:
// - Enable the corresponding date-fns locale in `datefns.helper.js`
// - Run i18next-locales-sync

type LanguageDefinition = {
    tag: string;
    rorTag: string;
    locale: string;
    nativeName: string;
    enName: string;
};

export const FALLBACK_LANGUAGE = "en-GB";

const LANGUAGE_DEFINITIONS_ACTIVE = {
    // Example:
    // "en-GB": {
    //     tag: "en-GB", // IETF BCP 47 language tags
    //     rorTag: "en-GB", // RoR I18n tags in thanksbox-api
    //     nativeName: "English",
    //     enName: "English",
    // },
    de: {
        tag: "de",
        rorTag: "de-DE",
        locale: "de_DE",
        nativeName: "Deutsch",
        enName: "German",
    },
    "en-GB": {
        tag: "en-GB",
        rorTag: "en-GB",
        locale: "en_GB",
        nativeName: "English",
        enName: "English",
    },
    es: {
        tag: "es",
        rorTag: "es-ES",
        locale: "es_ES",
        nativeName: "Español",
        enName: "Spanish",
    },
    fr: {
        tag: "fr",
        rorTag: "fr-FR",
        locale: "fr_FR",
        nativeName: "Français",
        enName: "French",
    },
    nl: {
        tag: "nl",
        rorTag: "nl-NL",
        locale: "nl_NL",
        nativeName: "Nederlandse",
        enName: "Dutch",
    },
    pl: {
        tag: "pl",
        rorTag: "pl-PL",
        locale: "pl_PL",
        nativeName: "Polski",
        enName: "Polish",
    },
    pt: {
        tag: "pt",
        rorTag: "pt-PT",
        locale: "pt_PT",
        nativeName: "Português",
        enName: "Portuguese",
    },
    ro: {
        tag: "ro",
        rorTag: "ro-RO",
        locale: "ro_RO",
        nativeName: "Română",
        enName: "Romanian",
    },
    ru: {
        tag: "ru",
        rorTag: "ru-RU",
        locale: "ru_RU",
        nativeName: "Русский",
        enName: "Russian",
    },
} as Readonly<Record<string, LanguageDefinition>>;

export const LANGUAGE_DEFINITIONS_LEGACY = {
    en: LANGUAGE_DEFINITIONS_ACTIVE["en-GB"],
    de_DE: LANGUAGE_DEFINITIONS_ACTIVE.de,
    en_GB: LANGUAGE_DEFINITIONS_ACTIVE["en-GB"],
    es_ES: LANGUAGE_DEFINITIONS_ACTIVE.es,
    fr_FR: LANGUAGE_DEFINITIONS_ACTIVE.fr,
    nl_NL: LANGUAGE_DEFINITIONS_ACTIVE.nl,
    pl_PL: LANGUAGE_DEFINITIONS_ACTIVE.pl,
    pt_PT: LANGUAGE_DEFINITIONS_ACTIVE.pt,
    ro_RO: LANGUAGE_DEFINITIONS_ACTIVE.ro,
    ru_RU: LANGUAGE_DEFINITIONS_ACTIVE.ru,
    "de-DE": LANGUAGE_DEFINITIONS_ACTIVE.de,
    "en-GB": LANGUAGE_DEFINITIONS_ACTIVE["en-GB"],
    "es-ES": LANGUAGE_DEFINITIONS_ACTIVE.es,
    "fr-FR": LANGUAGE_DEFINITIONS_ACTIVE.fr,
    "nl-NL": LANGUAGE_DEFINITIONS_ACTIVE.nl,
    "pl-PL": LANGUAGE_DEFINITIONS_ACTIVE.pl,
    "pt-PT": LANGUAGE_DEFINITIONS_ACTIVE.pt,
    "ro-RO": LANGUAGE_DEFINITIONS_ACTIVE.ro,
    "ru-RU": LANGUAGE_DEFINITIONS_ACTIVE.ru,
} as Readonly<Record<string, LanguageDefinition>>;

export const LANGUAGE_DEFINITIONS = {
    ...LANGUAGE_DEFINITIONS_LEGACY,
    ...LANGUAGE_DEFINITIONS_ACTIVE,
} as Readonly<Record<string, LanguageDefinition>>;

export const AVAILABLE_LANGUAGES = [
    "de",
    "en-GB",
    "es",
    "fr",
    "nl",
    "pl",
    "pt",
    "ro",
    "ru",
] as const;
