import React from "react";
import { Link } from "react-router-dom";

interface IndexActionsProps {
    label: string;
    to: string;
}

const IndexActions: React.FC<IndexActionsProps> = ({ label, to }) => (
    <Link
        className="p-2 px-4 text-lg font-bold text-white transition-all bg-purple-400 border border-purple-600 rounded-lg hover:bg-purple-600"
        to={to}
    >
        {label}
    </Link>
);

export default IndexActions;
