import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RequestByIdFromURL } from "../../models/types";
import { addErrorToast, addSuccessToast } from "../../helpers/addToast";
import AuthenticatedMoApiRequest, { FetchResponse } from "../AuthenticatedMoApiRequest";

const useDelete = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        async ({ id, url }: RequestByIdFromURL) => {
            if (!confirm(`Are you sure you want to delete item with the ID: '${id}'?`)) {
                throw new Error("Deletion cancelled");
            }

            return FetchResponse(
                new AuthenticatedMoApiRequest(new URL([url, id].join("/")), {
                    method: "DELETE",
                }),
            );
        },
        {
            onSuccess: (variables) => {
                queryClient.invalidateQueries(["boost", "cms"]);
                navigate(variables.url);
                addSuccessToast("Deleted");
            },
            onError: (error: Error) => {
                // The BE Error shape is different for templates and categories for some reason so pass error code in cause.
                const is404 = error.cause === 404;

                addErrorToast(
                    is404
                        ? "Could not delete this item, it may be another admin has recently deleted this. Please refresh the page and try again"
                        : error.message,
                );
            },
        },
    );
};

export default useDelete;
