import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BOOST_CMS_TEMPLATES_CREATE } from "../../../../constants/routes";
import { useInfiniteTemplates } from "../../../../queries/boost_cms/useBoostCMSTemplates";
import Pagination from "../../../../components/Pagination";
// eslint-disable-next-line import/order
import { useDebounce } from "usehooks-ts";
import TemplateTable from "../../../../components/templates/TemplateTable";
import { SelectOption } from "../../../../constants/form-constants";
import TemplateFilters from "../../../../components/templates/TemplateFilters";
import { useInfiniteHeadings } from "../../../../queries/boost_cms/useBoostCMSHeadings";
import { useInfiniteCategories } from "../../../../queries/boost_cms/useBoostCMSCategory";
import IndexTitle from "../../../../components/IndexTitle";
import IndexActions from "../../../../components/IndexActions";
import Loading from "../../../../components/Loading";

const CMSTemplatesIndex = () => {
    const params = useParams();

    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const [heading, setHeading] = useState<SelectOption>({ id: params.headingId || "", label: "" });
    const [category, setCategory] = useState<SelectOption>({
        id: params.categoryId || "",
        label: "",
    });
    const { data: headings } = useInfiniteHeadings({ search: "" });
    const { data: categories } = useInfiniteCategories({ search: "" });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const {
        data: paginatedResponse,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteTemplates({
        search: debouncedSearch,
        categoryId: category.id,
        headingId: heading.id,
    });

    const page = paginatedResponse?.pages[currentPage - 1];
    const templates = page?.results?.map((row) => ({
        id: row.id,
        title: row.title,
        published: row.published,
        archived: row.archived,
        root_locale: row.root_locale,
    }));

    if (!templates) return <Loading />;

    return (
        <>
            <IndexTitle title="Boost CMS Templates">
                <IndexActions to={BOOST_CMS_TEMPLATES_CREATE} label="Create" />
            </IndexTitle>

            <TemplateFilters
                searchValue={search}
                setSearchValue={setSearch}
                heading={heading}
                setHeading={setHeading}
                headings={headings?.pages[0].results}
                category={category}
                setCategory={setCategory}
                categories={categories?.pages[0].results}
            />

            <TemplateTable data={templates || []} isLoading={isLoading || isFetchingNextPage} />

            <Pagination
                numPages={page?.page_count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetchNextPage={fetchNextPage}
            />
        </>
    );
};

export default CMSTemplatesIndex;
