import React from "react";

interface IndexTitleProps {
    title: string;
    children?: React.ReactNode;
}

const IndexTitle: React.FC<IndexTitleProps> = ({ title, children }) => (
    <div className="flex items-center justify-between pb-4 mb-4 border-b border-b-gray-300">
        <h1 className="text-3xl font-bold ">{title}</h1>
        {children}
    </div>
);

export default IndexTitle;
