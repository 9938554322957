import React from "react";
import { RouteObject } from "react-router-dom";
import Login from "../routes/Login";
import {
    PATH_TRANSLATION_KEY,
    ACCESS_DENIED,
    BOOST_CMS_CATEGORIES_ROOT,
    BOOST_CMS_HEADINGS_ROOT,
    BOOST_CMS_TEMPLATES_ROOT,
    CONNECT_WITH_TOKEN_ROOT,
    LOGOUT,
    TRANSLATIONS_ROOT,
    PATH_BOOST_HEADINGS,
    LOGIN,
    BOOST_CMS_ROOT,
    ACTION_CREATE,
    PATH_BOOST_CATEGORIES,
    PATH_BOOST_TEMPLATES,
    ROOT,
} from "./routes";
import CMSHeadingsIndex from "../routes/boost/cms/heading/CMSHeadingsIndex";
import CMSCategoriesIndex from "../routes/boost/cms/categories/CMSCategoriesIndex";
import CMSTemplatesIndex from "../routes/boost/cms/templates/CMSTemplatesIndex";
import ConnectWithToken from "../routes/connect-with-token/ConnectWithToken";
import ManageLayout from "../layouts/ManageLayout";
import MainLayout from "../layouts/MainLayout";
import TranslationsIndex from "../routes/translations/TranslationsIndex";
import EditTranslation from "../routes/translations/EditTranslation";
import Logout from "../routes/Logout";
import CreateHeading from "../routes/boost/cms/heading/create/CreateHeading";
import EditHeading from "../routes/boost/cms/heading/edit/EditHeading";
import CreateCategory from "../routes/boost/cms/categories/create/CreateCategory";
import EditCategory from "../routes/boost/cms/categories/edit/EditCategory";
import CreateTemplate from "../routes/boost/cms/templates/create/CreateTemplate";
import EditTemplate from "../routes/boost/cms/templates/edit/EditTemplate";
import AccessDenied from "../routes/AccessDenied";
import RootPage from "../routes/RootPage";
import AuthenticationWrapper from "../components/AuthenticationWrapper";

const AppRoutes: RouteObject[] = [
    {
        path: ROOT,
        children: [
            { index: true, element: <RootPage /> },
            {
                path: LOGIN,
                element: <Login />,
            },
            {
                path: LOGOUT,
                element: <Logout />,
            },
            { path: ACCESS_DENIED, element: <AccessDenied /> },
            {
                path: `${CONNECT_WITH_TOKEN_ROOT}/:token`,
                element: <ConnectWithToken />,
            },
            {
                path: BOOST_CMS_ROOT,
                children: [
                    {
                        path: PATH_BOOST_HEADINGS,
                        element: (
                            <AuthenticationWrapper>
                                <MainLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CMSHeadingsIndex />,
                            },
                        ],
                    },
                    {
                        path: PATH_BOOST_HEADINGS,
                        element: (
                            <AuthenticationWrapper>
                                <ManageLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                path: ":headingId/edit",
                                element: <EditHeading />,
                                handle: {
                                    crumb: [
                                        { label: "Headings", to: BOOST_CMS_HEADINGS_ROOT },
                                        { label: "Edit", to: null },
                                    ],
                                },
                            },
                            {
                                path: ACTION_CREATE,
                                element: <CreateHeading />,
                                handle: {
                                    crumb: [
                                        { label: "Headings", to: BOOST_CMS_HEADINGS_ROOT },
                                        { label: "Create", to: null },
                                    ],
                                },
                            },
                        ],
                    },

                    {
                        path: PATH_BOOST_CATEGORIES,
                        element: (
                            <AuthenticationWrapper>
                                <MainLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CMSCategoriesIndex />,
                            },
                            {
                                path: `${PATH_BOOST_HEADINGS}/:headingId`,
                                element: <CMSCategoriesIndex />,
                            },
                        ],
                    },

                    {
                        path: PATH_BOOST_CATEGORIES,
                        element: (
                            <AuthenticationWrapper>
                                <ManageLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                path: ":categoryId/edit",
                                element: <EditCategory />,
                                handle: {
                                    crumb: [
                                        { label: "Categories", to: BOOST_CMS_CATEGORIES_ROOT },
                                        { label: "Edit", to: null },
                                    ],
                                },
                            },
                            {
                                path: ACTION_CREATE,
                                element: <CreateCategory />,
                                handle: {
                                    crumb: [
                                        { label: "Categories", to: BOOST_CMS_CATEGORIES_ROOT },
                                        { label: "Create", to: null },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        path: PATH_BOOST_TEMPLATES,
                        element: (
                            <AuthenticationWrapper>
                                <MainLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CMSTemplatesIndex />,
                            },
                            {
                                path: `${PATH_BOOST_HEADINGS}/:headingId`,
                                element: <CMSTemplatesIndex />,
                            },
                            {
                                path: `${PATH_BOOST_CATEGORIES}/:categoryId`,
                                element: <CMSTemplatesIndex />,
                            },
                        ],
                    },
                    {
                        path: PATH_BOOST_TEMPLATES,
                        element: (
                            <AuthenticationWrapper>
                                <ManageLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                path: ":templateId/edit",
                                element: <EditTemplate />,
                                handle: {
                                    crumb: [
                                        { label: "Templates", to: BOOST_CMS_TEMPLATES_ROOT },
                                        { label: "Edit", to: null },
                                    ],
                                },
                            },
                            {
                                path: ACTION_CREATE,
                                element: <CreateTemplate />,
                                handle: {
                                    crumb: [
                                        { label: "Templates", to: BOOST_CMS_TEMPLATES_ROOT },
                                        { label: "Create", to: null },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: TRANSLATIONS_ROOT,
                children: [
                    {
                        element: (
                            <AuthenticationWrapper>
                                <MainLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [
                            {
                                index: true,
                                element: <TranslationsIndex />,
                            },
                            {
                                path: `${PATH_TRANSLATION_KEY}/:translation_key`,
                                element: <TranslationsIndex />,
                            },
                            {
                                path: `${PATH_TRANSLATION_KEY}/:translation_key/:locale`,
                                element: <TranslationsIndex />,
                            },
                        ],
                    },
                    {
                        path: ":translationId/edit",
                        element: (
                            <AuthenticationWrapper>
                                <ManageLayout />
                            </AuthenticationWrapper>
                        ),
                        children: [{ index: true, element: <EditTranslation /> }],
                        handle: {
                            crumb: [
                                { label: "Translations", to: TRANSLATIONS_ROOT },
                                { label: "Edit", to: null },
                            ],
                        },
                    },
                ],
            },
        ],
    },
];

export default AppRoutes;
