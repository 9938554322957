import React from "react";
import { Link } from "react-router-dom";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";

interface TableActionItemProps {
    title?: string;
    to?: string;
    icon: string;
    onClick?: () => void;
}

const TableActionItem: React.FC<TableActionItemProps> = ({ title, to, icon, onClick }) => (
    <StatefulTooltip content={() => title} placement={PLACEMENT.top} showArrow>
        {to ? (
            <Link
                to={to}
                onClick={() => {
                    if (onClick) onClick();
                }}
                className="p-2 transition-colors border-none rounded-full hover:bg-slate-300"
            >
                {icon}
            </Link>
        ) : (
            <button
                className="p-2 transition-colors border-none rounded-full hover:bg-slate-300"
                type="button"
                onClick={onClick}
            >
                {icon}
            </button>
        )}
    </StatefulTooltip>
);

export default TableActionItem;
