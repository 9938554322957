import { styled } from "baseui";
import React from "react";
import { Outlet } from "react-router-dom";
import HeaderNavigation from "../components/navigation/HeaderNavigation";
import SubNavigation from "../components/navigation/SubNavigation";

const GridContainer = styled("section", () => ({
    display: "grid",
    gridTemplateColumns: "repeat(24, 1fr)",
    gridTemplateRows: "62px 52px 1fr",
    minHeight: "100vh",
}));

const GridContent = styled("main", () => ({
    gridRowStart: 3,
    gridColumnStart: 1,
    gridColumnEnd: 24,
    padding: "16px",
    display: "flex",
}));

const ManageLayout: React.FC = () => (
    <GridContainer>
        <HeaderNavigation />
        <SubNavigation />
        <GridContent>
            <Outlet />
        </GridContent>
    </GridContainer>
);

export default ManageLayout;
