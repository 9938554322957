import { Button, SIZE as BUTTON_SIZE } from "baseui/button";
import { OnChangeParams, Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useDebounce } from "usehooks-ts";
import { uniqBy } from "lodash";
import { RESPONSE_TYPE_OPTIONS } from "../../constants/form-constants";
import RootLanguageSelect from "../selects/RootLanguageSelect";
import BoolCheckBox from "../selects/BoolCheckBox";
import { BoostSettings } from "../../models/types";
import { Langs, TextFields } from "../../constants/enums";
import EmojiPicker from "../selects/EmojiPicker";
import ColourPicker from "../selects/ColourPicker";
import { useInfiniteCategories } from "../../queries/boost_cms/useBoostCMSCategory";
import Scheduler from "./Scheduler";
import DefaultText from "../inputs/DefaultText";

interface TemplateFormVals {
    title: string;
    published: boolean;
    archived: boolean;
    root_locale: Langs;
    message: string;
    value_proposition: string;
    cadence_hint: string;
    audience_hint: string;
    boost_settings: BoostSettings;
    category_ids: string[];
    author: string;
}

interface TemplateFormProps {
    initialValues: TemplateFormVals;
    isEdit: boolean;
    isSubmitting: boolean;
    onSubmit: (data: TemplateFormVals) => void;
    initialCategories?: { label: string; id: string }[] | [];
}

const TemplateForm: React.FC<TemplateFormProps> = ({
    initialValues,
    isEdit,
    isSubmitting,
    onSubmit,
    initialCategories = [],
}) => {
    const [search, setSearch] = React.useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const { data: categories, isLoading } = useInfiniteCategories({
        search: debouncedSearch,
        limit: "30",
    });

    const CATEGORY_OPTIONS =
        categories?.pages[0]?.results.map((cat) => ({
            id: cat?.id,
            label: cat?.name,
        })) || [];

    const formMethods = useForm<TemplateFormVals>({
        defaultValues: initialValues,
    });

    const { handleSubmit, control, setValue, getValues } = formMethods;

    const handleFormSubmit = (data: TemplateFormVals) =>
        onSubmit({ ...data, published: data.published });

    const handleLinkCategory = (option: OnChangeParams) => {
        const { category_ids } = getValues();
        const categoryId = option?.option?.id as string;

        let newCategories = category_ids || [];

        if (option.type === "remove" && option.option?.id)
            newCategories = newCategories.filter((cat) => cat !== option.option?.id);
        if (option.type === "select") newCategories = [...newCategories, categoryId];
        if (option.type === "clear") newCategories = [];

        setValue("category_ids", newCategories);
    };

    const formatLinkedCategories = () => {
        const { category_ids } = getValues();
        // If the category is already selected on load then we want to populate this from there
        // as not all categories are initially fetched.
        const allCategories = uniqBy([...initialCategories, ...CATEGORY_OPTIONS], "id");

        const categoryIdsWithLabels = category_ids?.map(
            (category: string) => allCategories?.find((c) => c?.id === category) || [],
        );

        return categoryIdsWithLabels || [];
    };

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit((data) => handleFormSubmit(data))}
                className="flex flex-col mb-6 "
            >
                <DefaultText
                    fieldName={TextFields.Title}
                    fieldLabel="Title"
                    placeholder="Title of the boost"
                />

                <DefaultText
                    fieldName={TextFields.ValueProp}
                    fieldLabel="Value Proposition"
                    placeholder="What's the value to the leader?"
                    isTextArea
                />

                <DefaultText
                    fieldName={TextFields.Message}
                    fieldLabel="Message"
                    placeholder="A great boost description"
                    isTextArea
                />

                <DefaultText
                    fieldName={TextFields.CadenceHint}
                    fieldLabel="Recommended usage"
                    placeholder="Weekly"
                />
                <DefaultText
                    fieldName={TextFields.AudienceHint}
                    fieldLabel="Who to add"
                    placeholder="Immediate team"
                />

                <DefaultText
                    fieldName={TextFields.Author}
                    fieldLabel="Author"
                    placeholder="Team Mo"
                />

                <Controller
                    name="category_ids"
                    control={control}
                    render={() => (
                        <FormControl label="Associated Categories">
                            <Select
                                value={formatLinkedCategories()}
                                isLoading={isLoading}
                                multi
                                required
                                placeholder="Select one or more categories"
                                options={CATEGORY_OPTIONS}
                                onChange={handleLinkCategory}
                                onInputChange={(e) => setSearch(e.target.value)}
                                clearable
                                onClose={() => setSearch("")}
                            />
                        </FormControl>
                    )}
                />

                <div className="grid w-full grid-cols-2 gap-4">
                    <Controller
                        control={control}
                        name="boost_settings.style.emoji"
                        render={({ field: { value, onChange } }) => (
                            <div>
                                <FormControl label={() => "Emoji"}>
                                    <EmojiPicker onChange={onChange} value={value} />
                                </FormControl>
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name="boost_settings.style.colour"
                        render={({ field: { ref, value, onChange } }) => (
                            <div>
                                <FormControl label={() => "Colour"}>
                                    <ColourPicker ref={ref} value={value} onChange={onChange} />
                                </FormControl>
                            </div>
                        )}
                    />
                </div>

                <Scheduler />

                <Controller
                    name="published"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl
                            label={() => "Published"}
                            caption={() =>
                                "Related categories, which are also published will be shown. It's still possible to hide specific categories."
                            }
                        >
                            <BoolCheckBox
                                label="Visible to everyone"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        </FormControl>
                    )}
                />
                <Controller
                    name="archived"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl
                            label={() => "Archived"}
                            caption={() =>
                                "Marks this template as deleted for all users, but can be accessed here."
                            }
                        >
                            <BoolCheckBox
                                label="Mark as deleted"
                                checked={value}
                                onChange={(e) => onChange(e.target.checked)}
                            />
                        </FormControl>
                    )}
                />
                <RootLanguageSelect name="root_locale" disabled={isEdit} />
                <Controller
                    name="boost_settings.response_type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControl label={() => "Response type"}>
                            <Select
                                disabled={false}
                                options={RESPONSE_TYPE_OPTIONS}
                                value={[
                                    {
                                        label: RESPONSE_TYPE_OPTIONS?.find(
                                            (option) => option?.id === value,
                                        )?.label,
                                        id: value,
                                    },
                                ]}
                                onChange={(option) => onChange(option.value[0].id)}
                                searchable={false}
                                clearable={false}
                                required
                            />
                        </FormControl>
                    )}
                />

                <Button
                    isLoading={isSubmitting}
                    type="submit"
                    className="place-self-center"
                    size={BUTTON_SIZE.large}
                >
                    {isEdit ? "Save" : "Create"}
                </Button>
            </form>
        </FormProvider>
    );
};

export default TemplateForm;
