/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

export enum ResponseType {
    POST = "post",
    TAG = "tag",
    MOOD = "mood",
}

export enum ResponseTypeLabels {
    "post" = "Post",
    "tag" = "Tag",
    "mood" = "Mood",
}

export enum Langs {
    EN = "en-GB",
    ES = "es-ES",
    PT = "pt-PT",
    DE = "de-DE",
    FR = "fr-FR",
    NL = "nl-NL",
    PL = "pl-PL",
    RO = "ro-RO",
    RU = "ru-RU",
    EN_US = "en-US",
}

export enum TranslationState {
    Required = "required",
    Submitted = "submitted",
    Processing = "processing",
    AwaitingApproval = "awaiting_approval",
    Failed = "failed",
    Done = "done",
}

export enum FlagMap {
    "en-GB" = "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "es-ES" = "🇪🇸",
    "pt-PT" = "🇵🇹",
    "de-DE" = "🇩🇪",
    "fr-FR" = "🇫🇷",
    "nl-NL" = "🇳🇱",
    "pl-PL" = "🇵🇱",
    "ro-RO" = "🇷🇴",
    "ru-RU" = "🇷🇺",
    "en-US" = "🇺🇸",
}

export enum SchedulerPeriod {
    Once = "once",
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
}

export enum SchedulerWeekdays {
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
}

export enum SchedulerMonthPos {
    First = "start",
    Last = "end",
}

export enum CookieKeys {
    AuthToken = "auth",
}

export enum TextFields {
    Title = "title",
    Message = "message",
    CadenceHint = "cadence_hint",
    ValueProp = "value_proposition",
    AudienceHint = "audience_hint",
    Author = "author",
    Translation = "translation",
    Name = "name",
}

export enum TextFieldLabels {
    "title" = "Title",
    "message" = "Message",
    "cadence_hint" = "Cadence Hint",
    "value_proposition" = "Value Proposition",
    "audience_hint" = "Audience Hint",
    "author" = "Author",
    "translation" = "Translation",
    "name" = "Name",
}

export enum Translatable {
    BoostHeading = "boost_headings",
    BoostCategory = "boost_categories",
    BoostTemplate = "boost_templates",
}

export enum TranslatableLabels {
    "boost_headings" = "Boost Heading",
    "boost_categories" = "Boost Category",
    "boost_templates" = "Boost Template",
}

export const TextCharLimits = {
    title: 32,
    value_proposition: 210,
    message: 120,
    cadence_hint: 40,
    audience_hint: 40,
    author: 200,
    translation: 2000,
    name: 100,
};
